import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; 
import MainContainer from "../../components/MainContainer";
import VirtualHumanInformationPage from "./VirtualHumanInformationPage";
import CharacterSelectPage from "./CharacterSelectPage";
import KnowledgeFormPage from "./KnowledgeFormPage";
import VoiceSelectPage from "./VoiceSelectPage";
import DialogueManagerPage from "./DialogueManagerPage";

import { createVirtualHuman } from "../../api/CreateVirtualHuman";
import { PutVirtualHuman } from "../../api/VirtualHumans";

const OnboardingStates = {
  INPUT_VIRTUALHUMAN_INFO: 0,
  SELECT_CHARACTER: 1,
  SELECT_VOICE: 2,
  SELECT_KNOWLEDGE: 3,
  SELECT_DM_AND_START_SIMULATION: 4,
};

const CreateVirtualHumanPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const VirtualHuman = location.state?.VirtualHuman;
  
  const userDetails = location.state?.userDetails;
  const projectData = location.state?.projectData;
  //console.log("userDetails: ",userDetails)
  //console.log("projectData: ",projectData)


  const [onboardingState, setOnboardingState] = useState(
    OnboardingStates.INPUT_VIRTUALHUMAN_INFO
  );
  const [previousVirtualHumanData, setPreviousVirtualHumanData] = useState(VirtualHuman);

  const [VirtualHumanInfo, setVirtualHumanInfo] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [voiceSettings, setVoiceSettings] = useState(null);
  const [knowledgeSettings, setKnowledgeSettings] = useState(null);
  const [DMSettings, setDMSettings] = useState(null);
  const [simulationReady, setSimulationReady] = useState({
    isLoading: false,
    isReady: false,
    isError: false,
  });


  const [vhSceneName, setVhSceneName] = useState(VirtualHuman.virtualhuman_name);
  const [vhSceneDescription, setVhSceneDescription] = useState(VirtualHuman.virtualhuman_description);


useEffect(() => {  
  setPreviousVirtualHumanData((prevData) => {  
    return { ...prevData, virtualhuman_name: vhSceneName };  
  });  
}, [vhSceneName]);  
  
useEffect(() => {  
  setPreviousVirtualHumanData((prevData) => {  
    return { ...prevData, virtualhuman_description: vhSceneDescription };  
  });  
}, [vhSceneDescription]);  


  useEffect(() => {
    console.log("VirtualHumanInfo is : ",VirtualHumanInfo)
    if (VirtualHumanInfo === null) {
      setOnboardingState(0);
    }
    else{
      if (selectedCharacter === null) {
        setOnboardingState(1);
      } 
      else {
        if (voiceSettings === null) {
          setOnboardingState(2);
        } 
        else 
        {
          if (knowledgeSettings === null) {
          console.log("knowledgeSettings is null")
          setOnboardingState(3);
          }
          else{
            if (DMSettings === null) {
              console.log("DMSettings is null")
              setOnboardingState(4);
            }
          }
        }
      }
    }


  }, [VirtualHumanInfo,selectedCharacter, voiceSettings, knowledgeSettings, DMSettings]);

  useEffect(() => {
    if (DMSettings !== null) {
      console.log("create!")
      console.log(DMSettings);
      if (
        !simulationReady.isLoading &&
        !simulationReady.isReady &&
        knowledgeSettings !== null
      ) {
        console.log("sending request");
        setSimulationReady({ isLoading: true, isReady: false });
      }
      console.log(previousVirtualHumanData.vhc_json_body)
      createVirtualHuman(previousVirtualHumanData.vhc_json_body)
      //   createVirtualPatient(knowledgeSettings)
          .then(() => {
            setSimulationReady({
              isLoading: false,
              isReady: true,
              isError: false,
            });
            saveCustomURL()
          })
          .catch((error) => {
            console.error("Error creating VH:", error);
            setSimulationReady({
              isLoading: false,
              isReady: false,
              isError: true,
            });
          });
    }
    
  }, [DMSettings]);

  const confirmVoiceSettings = (voice) => {
    setVoiceSettings(voice);
  };

  const confirmKnowledgeSettings = (knowledge) => {
    console.log("confirmKnowledgeSettings")
    setKnowledgeSettings(knowledge);
  };

  const startSimulation = () => {
    // window.location.href =
    //   `${process.env.REACT_APP_FURIOOS_URL}/play/?UserId=Testor&CharacterId=${previousVirtualHumanData.character_id}` +
    //   `&ConvoId=${previousVirtualHumanData.conversation_id}` +
    //   `&VoiceId=${previousVirtualHumanData.voice_id}` +
    //   `${
    //     previousVirtualHumanData.background_url
    //       ? `&CustomImageId=${previousVirtualHumanData.background_url}`
    //       : ""
    // }`;
    const IdProp = userDetails._id;
    const CharacterId = previousVirtualHumanData.character_id;
    const ConvoId = previousVirtualHumanData.conversation_id;
    const VoiceId = previousVirtualHumanData.voice_id;
    const CustomImageId = previousVirtualHumanData.background_url;
    const VirtualHumanId = previousVirtualHumanData._id;
    
    console.log("IdProp: " , IdProp)
    console.log("CharacterId: " , CharacterId)
    console.log("ConvoId: " , ConvoId)
    console.log("VoiceId: " , VoiceId)
    console.log("CustomImageId: " , CustomImageId)
    console.log("VirtualHumanId: " , VirtualHumanId)

    
    navigate('/play', {  
      state: {  
        IdProp: IdProp,  
        CharacterId: CharacterId,  
        ConvoId: ConvoId,  
        VoiceId: VoiceId,  
        CustomImageId: CustomImageId,  
        VirtualHumanId: VirtualHumanId,  
      },  
    }); 
  };

  const saveCustomURL = () => {
    console.log("saveCustomURL")
    previousVirtualHumanData.custom_url =
      `${process.env.REACT_APP_FURIOOS_URL}/play/?UserId=Testor&CharacterId=${previousVirtualHumanData.character_id}` +
      `&ConvoId=${convertToURLEncoded(previousVirtualHumanData.conversation_id)}` +
      `&VoiceId=${previousVirtualHumanData.voice_id}` +
      `${
        previousVirtualHumanData.background_url
          ? `&CustomImageId=${convertToURLEncoded(previousVirtualHumanData.background_url)}`
          : ""
    }`;
    console.log("previousVirtualHumanData: " + previousVirtualHumanData.custom_url)
    previousVirtualHumanData.status = "Deployed"

    PutVirtualHuman(previousVirtualHumanData._id, previousVirtualHumanData.virtualhuman_name, previousVirtualHumanData.virtualhuman_description, previousVirtualHumanData.organisation, previousVirtualHumanData.sub_organisation, previousVirtualHumanData.custom_url, previousVirtualHumanData.character_id, previousVirtualHumanData.voice_id, previousVirtualHumanData.background_url, previousVirtualHumanData.conversation_id, previousVirtualHumanData.status, previousVirtualHumanData.vhc_json_body)
  }

  const convertToURLEncoded = (string) => {
    return encodeURIComponent(string);
  };


  return (
    <MainContainer>
      {onboardingState === OnboardingStates.INPUT_VIRTUALHUMAN_INFO && (
        <VirtualHumanInformationPage
          setVirtualHumanInfo={setVirtualHumanInfo}
          previousVirtualHumanData={previousVirtualHumanData}
          setPreviousVirtualHumanData={setPreviousVirtualHumanData}
          projectData={projectData}
          userDetails={userDetails}

          vhSceneName={vhSceneName}
          setVhSceneName={setVhSceneName}

          vhSceneDescription={vhSceneDescription}
          setVhSceneDescription={setVhSceneDescription}


          />
      )}
      {onboardingState === OnboardingStates.SELECT_CHARACTER && (
        <CharacterSelectPage
          setSelectedCharacter={setSelectedCharacter}
          setVirtualHumanInfo={setVirtualHumanInfo}
          previousVirtualHumanData={previousVirtualHumanData}
          setPreviousVirtualHumanData={setPreviousVirtualHumanData}
          />
      )}
      {onboardingState === OnboardingStates.SELECT_VOICE && (
        <VoiceSelectPage
          selectedCharacter={selectedCharacter}
          previousVirtualHumanData={previousVirtualHumanData}
          setPreviousVirtualHumanData={setPreviousVirtualHumanData}
          setSelectedCharacter={setSelectedCharacter}
          confirmVoiceSettings={confirmVoiceSettings}
        />
      )}
      {onboardingState === OnboardingStates.SELECT_KNOWLEDGE && (
        <KnowledgeFormPage
          selectedCharacter={selectedCharacter}
          previousVirtualHumanData={previousVirtualHumanData}
          setVoiceSettings={setVoiceSettings}
          confirmKnowledgeSettings={confirmKnowledgeSettings}
          setPreviousVirtualHumanData={setPreviousVirtualHumanData}
          setKnowledgeSettings={setKnowledgeSettings}
          simulationReady={simulationReady}
          startSimulation={startSimulation}
        />
      )}
      {onboardingState === OnboardingStates.SELECT_DM_AND_START_SIMULATION && (
        <DialogueManagerPage
          selectedCharacter={selectedCharacter}
          setDMSettings={setDMSettings}
          setKnowledgeSettings={setKnowledgeSettings}
          previousVirtualHumanData={previousVirtualHumanData}
          setPreviousVirtualHumanData={setPreviousVirtualHumanData}
          simulationReady={simulationReady}
          startSimulation={startSimulation}
          projectData={projectData}
          userDetails={userDetails}
        />
      )}
    </MainContainer>
  );
};

export default CreateVirtualHumanPage;
