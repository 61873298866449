const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "organisations"
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`

export const getAvailableUsageForOrganisation = async (organisation) => {  
    try {  
      const response = await fetch(`${url}/${organisation}/available_usage`, {  
        method: 'GET',  
        headers: {  
          'Content-Type': 'application/json',  
          'CF-Access-Client-Id': clientId,
          'CF-Access-Client-Secret': clientSecret, 
        }  
      });  
    
      if (!response.ok) {  
        throw new Error(`Error ${response.status}: ${response.statusText}`);  
      }  
    
      const availableUsage = await response.json();  
      return availableUsage;  
    } catch (error) {  
      console.error('Error fetching available usage for organisation:', error);  
      throw error;  
    }  
  }
  
  export const getUserDetailsByOrganisation = async (organisation) => {  
    try {  
        const response = await fetch(`${url}/${organisation}/users`, {  
            method: 'GET',  
            headers: {  
                'Content-Type': 'application/json',  
                'CF-Access-Client-Id': clientId,  
                'CF-Access-Client-Secret': clientSecret,  
            }  
        });  
  
        if (!response.ok) {  
            throw new Error(`Error ${response.status}: ${response.statusText}`);  
        }  
  
        const userDetails = await response.json();  
        return userDetails;  
    } catch (error) {  
        console.error('Error fetching user details for organisation:', error);  
        throw error;  
    }  
}  