import { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import CreateVirtualHumanPage from "./pages/createVirtualHuman/CreateVirtualHumanPage";
import WelcomePage from "./pages/WelcomePage";
import TestUserDetailsAPIPage from "./pages/testUserDetailsAPIPage";
import TestProjectsAPIPage from "./pages/testProjectsAPIPage";
import TestVirtualHumansAPIPage from "./pages/testVirtualHumansAPIPage";
import TestTemplatesAPIPage from "./pages/testTemplatesAPIPage";

import ConvoAnalyticsPage from "./pages/ConvoAnalytics/ConvoAnalyticsPage";
import ConvoAnalyticsLandingPage from "./pages/ConvoAnalytics/ConvoAnalyticsLandingPage";

import DialogueManagerPage from "./pages/createVirtualHuman/DialogueManagerPage";
import VirtualHumansPage from "./pages/createVirtualHuman/VirtualHumansPage";


import ProjectsPage from "./pages/ProjectsPage";
import CreateProjectPage from "./pages/createProject/CreateProjectPage";

import IntentAnalyticsPage from "./pages/createVirtualHuman/IntentAnalytics";

const { default: PlayPage } = require("./pages/PlayPage");

function RequireAuth({ children, redirectTo, isTest }) {
  const { instance } = useMsal();
  let isAuthenticated = instance.getActiveAccount() !== null || isTest;

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
const App = ({ instance }) => {
  const [isTest, setIsTest] = useState(false);
  const localAccountId =
    instance && instance.getActiveAccount()
      ? instance.getActiveAccount()["localAccountId"]
      : "";

  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <Routes>
          {/* <Route
            path="/projects"
            element={
              <RequireAuth redirectTo="/" isTest={isTest}>
                element={<ProjectsPage id={localAccountId}/>}
              </RequireAuth>
            }
          /> */}
          {/* <Route
            path="/virtualhumans"
            element={
                <VirtualHumansPage id={localAccountId} />
            }
          /> */}
{/* 
          <Route
            path="/createVirtualHuman"
            element={
              <RequireAuth redirectTo="/" isTest={isTest}>
                <CreateVirtualHumanPage />
              </RequireAuth>
            }
          /> */}

          {/* <Route
            path="/play"
            element={
              <RequireAuth redirectTo="/" isTest={isTest}>
                <PlayPage IdProp={localAccountId} />{" "}
              </RequireAuth>
            }
          /> */}

          <Route
            exact
            path="/"
            element={<WelcomePage setIsTest={setIsTest} />}
          />

          <Route
            exact
            path="/projects"
            element={
              <RequireAuth redirectTo="/" isTest={isTest}>
            <ProjectsPage id={localAccountId}/>
            </RequireAuth>
          }
          />
          <Route
            path="/virtualhumans"
            element={
              <RequireAuth redirectTo="/" isTest={isTest}>
                <VirtualHumansPage id={localAccountId} />
              </RequireAuth>
            }
          />
          <Route
            path="/createVirtualHuman"
            element={     
              <RequireAuth redirectTo="/" isTest={isTest}>    
                <CreateVirtualHumanPage />
              </RequireAuth>   
            }
          />
          <Route
            path="/createProject"
            element={     
              <RequireAuth redirectTo="/" isTest={isTest}>    
                <CreateProjectPage/>
              </RequireAuth>   
            }
          />
          <Route
            path="/play"
            element={
            <RequireAuth redirectTo="/" isTest={isTest}>
            <PlayPage/>
            </RequireAuth>   
          }
          />

        <Route
            exact
            path="/intent-analytics" // This is for inputting intent-analytics data!!! Not for reviewing it
            element={
            <RequireAuth redirectTo="/" isTest={isTest}>
            <IntentAnalyticsPage />
            </RequireAuth>
            }
          />

        <Route
            exact
            path="/convo-analytics-landing-page"
            element={
            <RequireAuth redirectTo="/" isTest={isTest}>
            <ConvoAnalyticsLandingPage />
            </RequireAuth>
            }
          />

          <Route
            exact
            path="/convoai-analytics"
            element={
            <RequireAuth redirectTo="/" isTest={isTest}>
            <ConvoAnalyticsPage />
            </RequireAuth>
            }
          />


          {/* <Route
            exact
            path="/testUserDetailsAPIs"
            element={
            <TestUserDetailsAPIPage />
            }
          />
          <Route
            exact
            path="/testProjectsAPIs"
            element={
              <TestProjectsAPIPage />
            }
          />
          <Route
            exact
            path="/testVirtualHumansAPIs"
            element={
            <TestVirtualHumansAPIPage />}
          />
          <Route
            exact
            path="/testTemplatesAPIs"
            element={<TestTemplatesAPIPage />}
          />
          <Route
            exact
            path="/DialogueManagerPage"
            element={<DialogueManagerPage />}
          /> */}
          </Routes>

      </MsalProvider>
    </BrowserRouter>
  );
};

export default App;