const url = `${process.env.REACT_APP_VHC_BACKEND_WSS_URL}`
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`

// export const createVirtualPatient = async patientData => {
//     return fetch(url, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Connection': 'keep-alive'
//         },
//         body: JSON.stringify({
//             response: {
//                 data: patientData
//             }
//         }),
//     });
// };

function cleanUpJson(vhc_json_body) { //funciton specifically created to clean up old json objects
    let cleanedJson = JSON.parse(vhc_json_body);
    delete cleanedJson["response"]["data"]["Contact Number"]
    delete cleanedJson["response"]["data"]["Email Address"]
    delete cleanedJson["response"]["data"]["Place of Residence"]
    delete cleanedJson["response"]["data"]["Family Structure"]
    delete cleanedJson["response"]["data"]["Marital Status"]
    delete cleanedJson["response"]["data"]["Extended Family"]
    delete cleanedJson["response"]["data"]["Personality"]
    delete cleanedJson["response"]["data"]["Lifestyle"]
    delete cleanedJson["response"]["data"]["Habits"]
    delete cleanedJson["response"]["data"]["Interests and Hobbies"]
    delete cleanedJson["response"]["data"]["Level of Education"]
    delete cleanedJson["response"]["data"]["Social Class"]
    delete cleanedJson["response"]["data"]["Blood Group"]
    delete cleanedJson["response"]["data"]["context"]
    delete cleanedJson["response"]["data"]["mood"]
    return cleanedJson
}
export const createVirtualHuman = async vhc_json_body => {
    return new Promise((resolve, reject) => {
        console.log(url)  
        let createUrl = url + "create_virtual_human"

        console.log(createUrl)  
        vhc_json_body = cleanUpJson(vhc_json_body)
        console.log(vhc_json_body)

        const ws = new WebSocket(createUrl)
        
        ws.onopen = function(event) {
            console.log("Sending the following data to server")
            console.log(JSON.stringify(vhc_json_body))
            ws.send(JSON.stringify(vhc_json_body))
        }

        ws.onmessage = function(event) {
            console.log(event.data)
        }

        ws.onerror = function(error) {
            reject(error)
        }

        ws.onclose = function(event) {
            console.log(`event.code = ${event.code}`)
            if (event.code === 1000) {
                resolve()
            } else {
                reject(event)
            }
        }
    })
}