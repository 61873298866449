import TopBar from './TopBar'
import background from '../img/bg.png'

const MainContainer = ({ children }) => (

    <div className="h-screen px-12 py-6" style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'

    }}>

        <div className="flex flex-col h-full bg-blue-900/90 rounded-xl px-6 py-3">
            <TopBar />
            {children}
        </div>
    </div>
)

export default MainContainer