import React, { useState, useEffect } from "react";  
import { useNavigate } from "react-router-dom";  
import SideBar from "../components/SideBar";  
import MainContainer from "../components/MainContainer";  
import { getProjectsForOrganization, deleteProject, postProject } from "../api/Projects";  
import { getUserDetails } from "../api/UserDetails";
import ProjectCard from "../components/ProjectCard"; // Import the ProjectCard component  
  
const ProjectsPage = ({ id }) => {  
    const [projects, setProjects] = useState([]);
    const [organisation, setOrganisation] = useState("");
    const [subOrganisation, setSubOrganisation] = useState("");
    const [role, setRole] = useState("");
    const [name, setName] =useState("");
    const [updateProjectBool, setUpdateProjectBool] = useState(false);
    const [userDetails,setUserDetails] = useState();
    console.log(id)


    useEffect(() => { 

        const fetchProjectsForOrganization = async () => {  
            try {
                const userDetailsResponse = await getUserDetails(id); //returns a json response
                setUserDetails(userDetailsResponse[0])
                const org = userDetailsResponse[0].org;
                setOrganisation(org)
                const subOrg = userDetailsResponse[0].sub_org;
                setSubOrganisation(subOrg)
                const role = userDetailsResponse[0].role;
                setRole(role)
                const name = userDetailsResponse[0].name;
                setName(name)
                const response = await getProjectsForOrganization(org);
                setProjects(response);  
            }  
            catch (error) {  
                console.error("Error fetching projects:", error);  
            }  
        };
        fetchProjectsForOrganization();
        setUpdateProjectBool(false)
    }, [updateProjectBool]);  
  
    const handleDeleteProject = (projectId) => {  
        // Add logic to delete the project  
        try{
            deleteProject(projectId);
            setProjects(projects.filter((project) => project._id !== projectId));
        }
        catch (error) {
            console.error("Error deleting project:", error);
        }
    };  
    const newProject = async () => {  
        // Replace these values with the actual data from your form or application  
        const data = JSON.stringify({  
          organisation: organisation,  
          sub_organisation: subOrganisation,  
          created_by: id,  
          project_name: "Insert your project name here.",  
          project_description: "Insert your project description here.",  
          project_maximum_usage_time_per_period: 0,  
          account_access: "{}",  
          virtual_humans: "[]",  
        });  
        console.log(data)
        
        try {  
          const result = await postProject(data);  
            console.log("New project created:", result);  
            setUpdateProjectBool(true)
        } catch (error) {  
            console.error("Error creating new project:", error);  
        }  
      };  
  
    return (  
        <MainContainer>  
            <div id="Projects Page" className="flex-1 grid grid-cols-6 text-white">  
                <SideBar />  
                <div className="col-span-5 pl-4 overflow-y-auto scrollbar-hide">  
                    <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  Welcome, {name} </div>  
                    <div className="flex items-center">  
                        <div className="font-light text-m text-lg my-3">What would you like to do today?</div>  
                        <button  
                            className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w- my-4 ml-4 hover:bg-medivrlb-600"  
                            onClick={newProject}  
                        >  
                            Add New Project  
                        </button>  
                    </div>  
                    <div style={{ color: "grey", height: "600px", overflow: "auto" }} className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 rounded-md w-95">  
                        <style>  
                            {`  
                            ::-webkit-scrollbar {  
                                width: 0.75rem; // Make the scrollbar thicker  
                            }  
                            
                            ::-webkit-scrollbar-thumb {  
                                background-color: #FFFFFF;  
                                border-radius: 9999px;  
                            }  
                            ::-webkit-scrollbar-track {  
                                background-color: rgba(128, 128, 128, 0.5); 
                                border-radius: 9999px;
                            }  
                
                            // Align the scrollbar to the right  
                            .scrollbar-hide {  
                                direction: rtl;  
                            }  
                
                            .scrollbar-hide > * {  
                                direction: ltr;  
                            }  
                            `}  
                        </style>  

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">  
                            {projects.map((project) => (  
                                <ProjectCard key={project._id} project={project} userDetails={userDetails} onDelete={handleDeleteProject} />  
                            ))}  
                        </div>  
                    </div>  
                </div>  
            </div>  
        </MainContainer>  
    )  
}  
  
export default ProjectsPage;  
