import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    forgotPassword: "B2C_1A_PASSWORDRESET",
  },
  authorities: {
    signIn: {
      authority:
        process.env.REACT_APP_NODE_ENV === "production"
          ? "https://medivrioprod.b2clogin.com/medivrioprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
          : "https://medivriodev.b2clogin.com/medivriodev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    },
    forgotPassword: {
      authority:
        process.env.REACT_APP_NODE_ENV === "production"
          ? "https://medivrioprod.b2clogin.com/medivrioprod.onmicrosoft.com/B2C_1A_PASSWORDRESET"
          : "https://medivriodev.b2clogin.com/medivriodev.onmicrosoft.com/B2C_1A_PASSWORDRESET",
    },
  },
  // @clarence: change for deploys
  authorityDomain:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "medivrioprod.b2clogin.com"
      : "medivriodev.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
