import React from "react";
import { useNavigate} from "react-router-dom";
import minus_circle from '../img/minus-circle.png'
import { getCharacterByName } from "../data/CharacterData";
import vrLogo from "../img/vr_logo.png";

  
const VirtualHumanCard = ({ VirtualHuman, userDetails, projectData, onDelete }) => {  
    const navigate = useNavigate();

    const [vrButtonText, setVrButtonText] = React.useState("VR");

    const getImageSource = (characterId) => {    
      const character = getCharacterByName(characterId);  
      return character ? character.src : "";    
    };    

    const imageSource = getImageSource(VirtualHuman.character_id);


    const startSimulation = () => {
      //window.location.href =VirtualHuman.custom_url;
      const IdProp = userDetails._id;
      const CharacterId = VirtualHuman.character_id;
      const ConvoId = VirtualHuman.conversation_id;
      const VoiceId = VirtualHuman.voice_id;
      const CustomImageId = VirtualHuman.background_url;
      const VirtualHumanId = VirtualHuman._id;

      console.log("IdProp: " , IdProp)
      console.log("CharacterId: " , CharacterId)
      console.log("ConvoId: " , ConvoId)
      console.log("VoiceId: " , VoiceId)
      console.log("CustomImageId: " , CustomImageId)
      console.log("VirtualHumanId: " , VirtualHumanId)

      
      navigate('/play', {  
        state: {  
          IdProp: IdProp,  
          CharacterId: CharacterId,  
          ConvoId: ConvoId,  
          VoiceId: VoiceId,  
          CustomImageId: CustomImageId,  
          VirtualHumanId: VirtualHumanId,  
        },  
      }); 
    };

    const goToAnalytics = () => {
      navigate('/convo-analytics-landing-page', {
        state: {
          virtualHuman: VirtualHuman,
          projectData: projectData,
          userDetails: userDetails,
        },
      });
    };

    const handleVrButtonClick = () => {  
      setVrButtonText(vrButtonText === "VR" ? VirtualHuman.vr_code : "VR");  
    };  
    
      
  return (
    <div className="bg-black70 rounded-lg p-4 my-3 mx-4" style={{  
      display: "flex",  
      flexDirection: "row",
      alignItems: "center",  
      width: "600px", // set the width to 373px 
      height: "350px", // set the height to 248.27px 
      borderRadius: "10px", // set the corner radius to 10px  
      padding: "16px", // set the padding to 16px  
      }}> 
      {(userDetails.role === "operator"|| userDetails.role === "developer") && ( 
        <button  
          // className="bg-red-500 text-white font-light rounded-full px-1 py-1 md:w-8 hover:bg-red-600"  
          style={{ alignSelf: "flex-start" }} 
          onClick={() => {  
            onDelete(VirtualHuman._id);  
          }}  
        >  
          <img src={minus_circle} alt="Delete" style={{ width: "20px", height: "20px" }} />  
        </button> 
      )} 
      <img src={imageSource} alt={VirtualHuman.character_id} style={{ width: "250px", height: "300px", objectFit: "cover", marginRight: "0.5rem"}} />  
      {/* 
      24th May 2023, Sabrina
        Changed text-white to text-slate-300 for all text-sm
        For status label, added ':'
        Changed text-l to text-lg for {VirtualHuman.virtualhuman_name} div
      */}  
      <div className="bw-full md:w-9/12 lg:w-4/12">  
        <div className="text-white text-lg font-medium my-3">{VirtualHuman.virtualhuman_name}</div>    
        {/* <div className="text-sm text-white" style={{ marginBottom: "0.5rem" }}>{VirtualHuman.virtualhuman_description}</div>   */}  
        <div className="text-sm text-slate-300">Date Added:</div>    
        <div className="text-white text-l font-medium" style={{ marginBottom: "0.5rem" }}>{VirtualHuman.date_added}</div>  
        <div className="text-sm text-slate-300">Last Edited:</div>    
        <div className="text-white text-l font-medium" style={{ marginBottom: "0.5rem" }}>{VirtualHuman.last_edited}</div>    
        <div className="text-sm text-slate-300">Status:</div>  
        {/* Changed text-white to text-green-300 and made the text uppercase*/}   
        <div className="uppercase text-green-300 text-l font-medium" style={{ marginBottom: "0.5rem" }}>{VirtualHuman.status}</div>    
        {/* Render other VirtualHuman details */}    
      </div>    
      <div className="w-full md:w-3/12 lg:w-2/12 flex justify-end mt-5 md:mt-0 flex-col">  
        <div className="mb-2">  
          {(userDetails.role === "operator"|| userDetails.role === "developer") && ( 
          <button  
            className="bg-amber-500 text-white font-medium rounded-full px-2 py-1 w-full md:w-30 my-3 hover:bg-amber-600"  
            onClick={() => {  
              navigate("/createVirtualHuman", { state: { VirtualHuman, userDetails, projectData } });  
            }}  
          >  
            Edit  
          </button> 
          )} 
        </div>  
        <div className="mb-2">  
          {VirtualHuman.status !== "Unpublished" && (  
            <button  
              className="bg-blue-500 text-white font-medium rounded-full px-2 py-1 w-full md:w-30 my-3 hover:bg-blue-600"  
              onClick={() => {  
                startSimulation();  
              }}  
            >  
              Start  
            </button>  
          )}  
        </div>   
        <div className="mb-2">  
          <button  
            className="bg-blue-500 text-white font-medium rounded-full px-2 py-1 w-full md:w-30 my-3 hover:bg-blue-600"  
            onClick={() => {  
              goToAnalytics();  
            }}  
          >  
            Analytics  
          </button>  
        </div> 
        <div className="mb-2">  
        {VirtualHuman.vr_code !== undefined && userDetails.vr_user === "enabled" && (  
            <button  
              className={  
                vrButtonText === "VR"  
                  ? "rounded-full px-2 py-1 w-full md:w-30 my-3"  
                  : "bg-blue-500 text-white font-medium rounded-full px-2 py-1 w-full md:w-30 my-3 hover:bg-blue-600"  
              }  
              onClick={handleVrButtonClick}  
            >  
              {vrButtonText === "VR" ? (  
                <div className="flex justify-center items-center">  
                  <img  
                    src={vrLogo}  
                    alt="VR"  
                    className="w-12 h-5" // Replace the inline styles with equivalent Tailwind classes  
                  />  
                </div>  
              ) : (  
                vrButtonText  
              )}  
            </button>  
          )}  


 
        </div>   
      </div>  
    </div>  
  );  
};  
  
export default VirtualHumanCard;  