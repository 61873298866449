
export const formQuestions = [
    {
        title: 'Metadata',
        questions: [
            {
                name: 'UID',
                label: 'UID (Unique Idenfitier for this creation)',
                placeholder: 'VH Student',
                type: 'text'
            },
            {
                name: 'role1',
                label: 'Role of the virtual human',
                placeholder: 'Patient',
                type: 'text'
            },
            {
                name: 'role2',
                label: 'Role of the user',
                placeholder: 'Doctor',
                type: 'text'
            },
            // {
            //     name: 'context',
            //     alt_name: 'Case Note',
            //     label: 'Case Note',
            //     placeholder: '',
            //     type: 'text'
            // },
            {
                name: 'convo_background',
                alt_name: 'Convo Background',
                label: 'Background of the conversation',
                placeholder: '',
                type: 'text'
            },
            {
                name: 'convo_mood',
                alt_name: 'Convo Mood',
                label: 'Emotions of the virtual human',
                placeholder: 'Anxious',
                type: 'text'
            },
        ]
    },
    {
        title: 'General Categories - Personal',
        questions: [
            {
                name: 'personal_name',
                label: 'Name',
                placeholder: 'Mandy Tan Ai Ling',
                type: 'text'
            },
            {
                name: 'personal_ic',
                alt_name: 'NRIC',
                label: 'Identity Card (IC) Number',
                placeholder: 'S9812476F',
                type: 'text'
            },
            {
                name: 'personal_dob',
                label: 'Date of Birth',
                placeholder: '2022-12-12',
                type: 'date'
            },
            // {
            //     name: 'personal_contact',
            //     label: 'Contact Number',
            //     placeholder: '96821326',
            //     type: 'number'
            // },
            // {
            //     name: 'personal_email',
            //     label: 'Email Address',
            //     placeholder: 'mandytan@gmail.com',
            //     type: 'email'
            // },
            // {
            //     name: 'personal_address',
            //     label: 'Place of Residence',
            //     placeholder: '100 Loewen Rd, Singapore 248837',
            //     type: 'text'
            // },
        ]
    }, {
        title: 'General Categories - Demographic',
        questions: [
            {
                name: 'demographic_age',
                label: 'Age',
                placeholder: 54,
                type: 'number'
            },
            {
                name: 'demographic_gender',
                label: 'Gender',
                placeholder: 'Female',
                type: 'text'
            },
            {
                name: 'demographic_ethnicity',
                label: 'Ethnicity',
                placeholder: 'Chinese',
                type: 'text'
            },
            {
                name: 'demographic_nationality',
                label: 'Nationality',
                placeholder: 'Singaporean',
                type: 'text'
            },
            {
                name: 'demographic_religion',
                label: 'Religion',
                placeholder: 'Buddhist',
                type: 'text'
            },
            {
                name: 'demographic_occupation',
                label: 'Occupation',
                placeholder: 'Works as a cleaner at ION Orchard',
                type: 'text'
            },

            // {
            //     name: 'demographic_family',
            //     label: 'Family Structure',
            //     placeholder: '3 children. 2 older daughters and 1 youngest son.',
            //     type: 'text'
            // },
            // {
            //     name: 'demographic_marital',
            //     label: 'Marital Status',
            //     placeholder: 'Married',
            //     type: 'text'
            // },
            // {
            //     name: 'demographic_extended',
            //     label: 'Extended Family',
            //     placeholder: '2 cousins',
            //     type: 'text'
            // },
        ]
    }, 
    // {
    //     title: 'Psychographic',
    //     questions: [
    //         // {
    //         //     name: 'psychographic_personality',
    //         //     label: 'Personality',
    //         //     placeholder: 'I believe I am optimistic and free-spirited.',
    //         //     type: 'text'
    //         // },
    //         // {
    //         //     name: 'psychographic_lifestyle',
    //         //     label: 'Lifestyle',
    //         //     placeholder: 'I exercise twice a week by playing basketball.',
    //         //     type: 'text'
    //         // },
    //         // {
    //         //     name: 'psychographic_habits',
    //         //     label: 'Habits',
    //         //     placeholder: 'I wake up at 5am every morning.',
    //         //     type: 'text'
    //         // },
    //         // {
    //         //     name: 'psychographic_interests_and_hobbies',
    //         //     label: 'Interests and Hobbies',
    //         //     placeholder: 'I like to bake during my free time.',
    //         //     type: 'text'
    //         // },
    //     ]
    // }, 
    {
        title: 'Socio-Economic',
        questions: [
            // {
            //     name: 'socioeconomic_education',
            //     label: 'Level of Education',
            //     placeholder: 'Diploma in Business Administration',
            //     type: 'text'
            // },
            {
                name: 'socioeconomic_income',
                label: 'Income',
                placeholder: 'I earn 5k a month.',
                type: 'text'
            },
            // {
            //     name: 'socioeconomic_class',
            //     label: 'Social Class',
            //     placeholder: 'I consider myself as middle class',
            //     type: 'text'
            // },
        ]
    }, {
        title: 'Biometric',
        questions: [
            {
                name: 'biometric_height',
                label: 'Height',
                placeholder: '164cm',
                type: 'text'
            },
            {
                name: 'biometric_weight',
                label: 'Weight',
                placeholder: '56kg',
                type: 'text'
            },
            // {
            //     name: 'biometric_blood',
            //     label: 'Blood Group',
            //     placeholder: 'O+',
            //     type: 'text'
            // }
        ]
    }, {
        title: 'Specific',
        questions: [
            {
                name: 'case_note',
                label: 'Case Note',
                placeholder: 'I smoke twice a day. I have frequent headaches. I have yet to see the doctor for my problems.',
                type: 'text'
            }
        ]
    }
]