import { useEffect, useState } from "react";  
import { useSearchParams } from "react-router-dom";  
import { useNavigate } from "react-router-dom";  
import SideBar from "../../components/SideBar";  
import {  
  getUserDetailsByOrganisation,  
} from "../../api/Organisations";  
import {  
  getAvailableUsageForOrganisation,  
} from "../../api/Organisations";  
import {  
  addUsersToProject,  
} from "../../api/Projects";  
import {  
  removeUsersFromProject,  
  updateProjectMaximumUsageTime,  
} from "../../api/Projects";  
import classNames from "classnames";  
  
const AccessPage = ({  
  myUserDetail,  
  previousProjectData,  
  setPreviousProjectData,  
  setSelectedUsers,  
}) => {  
  const navigate = useNavigate();  
  
  console.log("AccessPage");  
  const [changesMade, setChangesMade] = useState(false);  
  const [ProjectName, setProjectName] = useState(  
    previousProjectData.project_name  
  );  
  const [ProjectDescription, setProjectDescription] = useState(  
    previousProjectData.project_description  
  );  
  const [userDetails, setUserDetails] = useState([]);  
  const [maxUsageForProject, setMaxUsageForProject] = useState(0);  
  const [overallUsage, setOverallUsage] = useState(  
    previousProjectData.project_maximum_usage_time_per_period  
  );  
  const [showWarning, setShowWarning] = useState(false);  
  const [checkedUsers, setCheckedUsers] = useState(new Set());  
  const [showConfirmModal, setShowConfirmModal] = useState(false);  
  const [confirmedOverallUsage, setConfirmedOverallUsage] = useState(  
    overallUsage  
  );  
  
  const [selectedUserExists, setSelectedUserExists] = useState(false);  
  
  console.log("the previous project data is: ", previousProjectData);  
  
  useEffect(() => {  
    const fetchUserDetails = async () => {  
      try {  
        const userDetails = await getUserDetailsByOrganisation(  
          previousProjectData.projects_metadata.organisation  
        );  
        setUserDetails(userDetails);  
        console.log("user details:", userDetails);  
      } catch (error) {  
        console.error("Error fetching user details:", error);  
      }  
    };  
    const fetchAvailableUsageForProject = async () => {  
      try {  
        const details = await getAvailableUsageForOrganisation(  
          previousProjectData.projects_metadata.organisation  
        );  
        console.log("available usage details:", details);  
        setMaxUsageForProject(details.available_usage);  
      } catch (error) {  
        console.error("Error fetching details:", error);  
      }  
    };  
    fetchUserDetails();  
    fetchAvailableUsageForProject();  
  }, []);  
  
  useEffect(() => {  
    // Check if any of the selected users exist in account_access  
    const exists = Array.from(checkedUsers).some(  
      (userId) => Boolean(previousProjectData.account_access[userId])  
    );  
  
    setSelectedUserExists(exists);  
  }, [checkedUsers, previousProjectData.account_access]);  
  
  const saveAsDraft = () => {  
    if (changesMade === true) {  
    } else {  
      console.log("No changes made");  
    }  
  };  
  
  const handleOverallUsageChange = (event) => {  
    const value = parseInt(event.target.value, 10);  
    setOverallUsage(value);  
  
    if (value > maxUsageForProject) {  
      setShowWarning(true);  
    } else {  
      setShowWarning(false);  
    }  
  };  
  
  const handleMasterCheckbox = (event) => {  
    const isChecked = event.target.checked;  
    const updatedCheckedUsers = isChecked  
      ? new Set(userDetails.map((user) => user._id))  
      : new Set();  
    setCheckedUsers(updatedCheckedUsers);  
  };  
  
  const handleUserCheckbox = (event, userId) => {  
    const isChecked = event.target.checked;  
    const updatedCheckedUsers = new Set(checkedUsers);  
  
    if (isChecked) {  
      updatedCheckedUsers.add(userId);  
    } else {  
      updatedCheckedUsers.delete(userId);  
    }  
  
    setCheckedUsers(updatedCheckedUsers);  
  };  
  
  const addCheckedUsersToProject = async () => {  
    try {  
      const projectId = previousProjectData._id; // Replace with the actual project ID  
      const editedBy = myUserDetail.name; // Replace with the actual editor's username or ID  
      const newUserIds = Array.from(checkedUsers);  
      console.log("newUserIds", newUserIds);  
      const result = await addUsersToProject(  
        projectId,  
        newUserIds,  
        editedBy  
      );  
      console.log("Users added to project:", result);  
      setPreviousProjectData(result);  
    } catch (error) {  
      console.error("Error adding users to project:", error);  
    }  
  };  
  const removeCheckedUsersFromProject = async () => {  
    try {  
      const projectId = previousProjectData._id; // Replace with the actual project ID  
      const editedBy = myUserDetail.name; // Replace with the actual editor's username or ID  
      const userIdsToRemove = Array.from(checkedUsers);  
      console.log("userIdsToRemove", userIdsToRemove);  
      const result = await removeUsersFromProject(  
        projectId,  
        userIdsToRemove,  
        editedBy  
      );  
      console.log("Users removed from project:", result);  
      setPreviousProjectData(result);  
    } catch (error) {  
      console.error("Error removing users from project:", error);  
    }  
  };  
  
  const ConfirmModal = () => {  
    return (  
      <div className="fixed z-10 inset-0 overflow-y-auto">  
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">  
          <div className="fixed inset-0 transition-opacity">  
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>  
          </div>  
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">  
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">  
              <h3 className="text-lg leading-6 font-medium text-gray-900">  
                Confirm Maximum Overall Usage  
              </h3>  
              <div className="mt-2">  
                <p className="text-sm text-gray-500">  
                  Are you sure you want to update the Maximum Overall Usage to{" "}  
                  {overallUsage}?  
                </p>  
              </div>  
            </div>  
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">  
              <button  
                type="button"  
                className="w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:text-sm"
                onClick={async () => {  
                  setConfirmedOverallUsage(overallUsage);  
                  setShowConfirmModal(false);  
                  try {  
                    const result = await updateProjectMaximumUsageTime(  
                      previousProjectData._id,  
                      myUserDetail.name,  
                      overallUsage  
                    );  
                    console.log(  
                      "Project maximum usage time updated:",  
                      result  
                    );  
                    setPreviousProjectData({  
                      ...previousProjectData,  
                      project_maximum_usage_time_per_period: overallUsage,  
                    });  
                  } catch (error) {  
                    console.error(  
                      "Error updating project maximum usage time:",  
                      error  
                    );  
                  }  
                }}  
              >  
                Confirm  
              </button>  
              <button  
                type="button"  
                className="w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm"
                onClick={() => setShowConfirmModal(false)}  
              >  
                Cancel  
              </button>  
            </div>  
          </div>  
        </div>  
      </div>  
    );  
  };  
  
  return (  
    <div className="flex-1 grid grid-cols-6 text-white text-left">  
      {showConfirmModal && <ConfirmModal />}  
      <SideBar />  
      <div className="col-span-5 p-4">  
      <div className="col-span-5 p-4">  
  <h2 className="text-xl font-semibold mb-4">Usage</h2>  
  
  <div className="mb-4">  
    <label htmlFor="maxOverallUsage" className="block mb-2">  
      Maximum Overall Usage  
    </label>  
    <div className="flex items-center mb-4">  
      <input  
        type="number"  
        id="maxOverallUsage"  
        className="border px-2 py-1 text-black"  
        value={overallUsage}  
        onChange={handleOverallUsageChange}  
      />  
      <button  
        className="ml-2 inline-flex items-center p-1 text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"  
        onClick={() => setShowConfirmModal(true)}  
      >  
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">  
                <polyline points="20 6 9 17 4 12"></polyline>  
        </svg>  
      </button>  
      {showWarning && (  
        <p className="text-red-500 mt-2">  
          Warning: Maximum Overall Usage exceeds available usage.  
        </p>  
      )}  
    </div>  
  </div>  
</div>  

        <h2 className="text-xl font-semibold mb-4">User Details</h2>  
        <div className="mb-2 flex-col">  
          <div className="flex justify-end mb-2">  
            <button  
              className="bg-green-500 font-medium rounded-full px-2 py-1 h-12 w-45 my-4 hover:bg-green-600 text-white my-3 mx-4"  
              onClick={() => {  
                navigate("/virtualhumans", {  
                  state: {  
                    projectData: previousProjectData,  
                    userDetails: myUserDetail,  
                  },  
                });  
              }}  
            >  
              Go to Virtual Humans  
            </button>  
          </div>  
          <div className="flex justify-end">  
            <button  
              className={`px-4 py-2 rounded text-white mr-2 ${  
                checkedUsers.size > 0 && !selectedUserExists  
                  ? "bg-blue-500"  
                  : "bg-gray-500"  
              }`}  
              onClick={() => {  
                if (checkedUsers.size > 0 && !selectedUserExists) {  
                  addCheckedUsersToProject();  
                }  
              }}  
            >  
              Add Account(s)  
            </button>  
            <button  
              className={`px-4 py-2 rounded text-white ${  
                checkedUsers.size > 0 && selectedUserExists  
                  ? "bg-orange-500"  
                  : "bg-gray-500"  
              }`}  
              onClick={() => {  
                if (checkedUsers.size > 0 && selectedUserExists) {  
                  removeCheckedUsersFromProject();  
                }  
              }}  
            >  
              Remove Account(s)  
            </button>  
          </div>  
        </div>  
  
        <div className="overflow-auto max-h-[300px]">  
          <table className="min-w-full bg-white text-black divide-y divide-gray-200">  
            <thead className="bg-gray-300">  
              <tr>  
                <th className="px-4 py-2">  
                  <input type="checkbox" onChange={handleMasterCheckbox} />  
                </th>  
                <th className="px-4 py-2">Name</th>  
                <th className="px-4 py-2">Organisation</th>  
                <th className="px-4 py-2">Email Address</th>  
                <th className="px-4 py-2">Role</th>  
                <th className="px-4 py-2">Max Usage</th>  
              </tr>  
            </thead>  
            <tbody className="divide-y divide-gray-200">  
              {userDetails.map((user, index) => (  
                <tr  
                  key={index}  
                  // Apply the blue background color if the user ID exists in account_access  
                  className={  
                    previousProjectData.account_access[user._id]  
                      ? "bg-blue-200"  
                      : ""  
                  }  
                >  
                  <td className="px-4 py-2">  
                    <input  
                      type="checkbox"  
                      checked={checkedUsers.has(user._id)}  
                      onChange={(event) =>  
                        handleUserCheckbox(event, user._id)  
                      }  
                    />  
                  </td>  
                  <td className="px-4 py-2">{user.name}</td>  
                  <td className="px-4 py-2">{user.org}</td>  
                  <td className="px-4 py-2">{user.email_address}</td>  
                  <td className="px-4 py-2">{user.role}</td>  
                  <td className="px-4 py-2">  
                    {" "}  
                    {previousProjectData.account_access[user._id]  
                      ? previousProjectData.account_access[  
                          user._id  
                        ].user_maximum_usage_time_per_period  
                      : " - "}  
                  </td>  
                </tr>  
              ))}  
            </tbody>  
          </table>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default AccessPage;  
