// Page is open to public access
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
// import { InteractionStatus } from "@azure/msal-browser";

const loginRequest = {
  scopes: [],
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const WelcomePage = ({ setIsTest }) => {
  const [isDevEnv, setIsDevEnv] = useState(false);

  const { instance } = useMsal();
  // const [isTest, setIsTest] = useState(false);
  const navigate = useNavigate();

  let activeAccount;

  const handleLoginRedirect = async () => {
    await sleep(1000);
    instance
      .loginRedirect(loginRequest)
      .then((res) => console.log("Response found in welcome: ", res))
      .catch((error) => console.log("Error found in welcome: ", error));
  };

  useEffect(() => {
    const currentURL = window.location.toString();

    // console.log(
    //   "Is this a test or dec env: ",
    //   currentURL.includes("localhost") ||
    //     (currentURL.includes("dev") && !currentURL.includes("stage"))
    // );

    if (
      !(
        currentURL.includes("localhost") ||
        (currentURL.includes("dev") && !currentURL.includes("stage"))
      ) &&
      !(instance && instance.getActiveAccount())
    ) {
      // if not localhost, nor dev env (not stage), and is empty instance
      handleLoginRedirect();
    } else if (
      currentURL.includes("localhost") ||
      (currentURL.includes("dev") && !currentURL.includes("stage"))
    ) {
      // if localhost and is some dev branch
      setIsDevEnv(true);
    }

    if (instance && instance.getActiveAccount()) {
      console.log("Welcome page: instance found, getting active account..");
      activeAccount = instance.getActiveAccount();
      //navigate("/virtualhumans");
      navigate("/projects");
    }
  }, []);

  return (
    <>
      {/* <UnauthenticatedTemplate> */}
      <div className="loader-container">
        <div className="spinner">
          {isDevEnv ? (
            <>
              <button
                style={{
                  border: "1px solid blue",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "15px",
                  padding: "20px",
                }}
                className="btn btn-primary"
                onClick={() => {
                  setIsTest(true);
                //navigate("/virtualhumans");
                navigate("/projects");
                }}
              >
                Bypass Auth
              </button>
              <button
                style={{
                  border: "1px solid blue",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "15px",
                  padding: "20px",
                }}
                className="btn btn-primary"
                onClick={handleLoginRedirect}
              >
                Redirect
              </button>
              <a
                style={{
                  border: "1px solid blue",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "15px",
                  padding: "20px",
                }}
                className="btn btn-primary"
                href="/sample.html"
              >
                Show HTML
              </a>
            </>
          ) : null}
        </div>
      </div>
      {/* </UnauthenticatedTemplate> */}
    </>
  );
};

export default WelcomePage;
