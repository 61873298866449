import { useState, useEffect } from "react";  
import { useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../components/SideBar";  
import { getUserDetails } from "../../api/UserDetails";
import { getVirtualHumansByOrg, getVirtualHumansBySubOrg, PostVirtualHuman, getVirtualHumanById, getVirtualHumansByIds, PutVirtualHuman, deleteVirtualHuman} from "../../api/VirtualHumans";
import { addVirtualHumansToProject, removeVirtualHumansFromProject, getProjectById } from "../../api/Projects";
import VirtualHumanCard from "../../components/VirtualHumanCard";
import vhcJsonBodyData from "../../data/vhcJsonBodyData";
import MainContainer from "../../components/MainContainer";

const VirtualHumansPage = ({  }) => {  
  const [getUserDetailsOutput, setGetUserDetailsOutput] = useState("");
  const [getVirtualHumansOutput, setGetVirtualHumansOutput] = useState("");
  const [role, setRole] = useState("");  
  const [org, setOrg] = useState("");  
  const [subOrg, setSubOrg] = useState("");
  const [sortOption, setSortOption] = useState("");
  const navigate = useNavigate();
  const [updateVirtualHumanBool, setUpdateVirtualHumanBool] = useState(false);

  const location = useLocation();  
  let projectData = location.state?.projectData; 
  const userDetails = location.state?.userDetails; 
  const id = userDetails._id;
  
  // console.log("projectData: " , projectData)
  // console.log("virtualhumans: " , projectData.virtual_humans)
  // console.log("id: " , id)

  useEffect(() => {  
    const fetchUserDetails = async () => {  
      try {  
        const response = await getUserDetails(id); //returns a json response  
        setGetUserDetailsOutput(JSON.stringify(response));
        const { role, org, sub_org } = response[0];  
        setRole(role);  
        setOrg(org);
        setSubOrg(sub_org);
        console.log("role: " + role + ", org: " + org + ", sub_org: " + sub_org)
        // fetchVirtualHumans(org,sub_org);
        }
        catch (error) {
        setGetUserDetailsOutput(JSON.stringify(error));
        }
    };  
    const fetchVirtualHumans = async () => {
      try {
        let response;
        projectData = await getProjectById(projectData._id);
        response = await getVirtualHumansByIds(projectData.virtual_humans); //returns a json response
        console.log("response: " + response)
        setGetVirtualHumansOutput(JSON.stringify(response));
      }
      catch (error) {
        setGetVirtualHumansOutput(JSON.stringify(error));
      }
    };

    fetchUserDetails();  
    fetchVirtualHumans();
    setUpdateVirtualHumanBool(false)
  }, [id, updateVirtualHumanBool]);  
  
  const newVirtualHuman = async () => {  
    console.log("Route to create page, and pass in jsonMessage = null");
    const vhc_json_body = vhcJsonBodyData;
    console.log(vhc_json_body)
    //post a new VirtualHuman and get the _id. Then route to create page with the completed VirtualHuman json message
    let response = await PostVirtualHuman(
    "Insert Virtual Human Name Here",
    "Insert Virtual Human Description Here",
    org,
    subOrg,
    "None", 
    "Chinese_Adult_F_01c", 
    "chinese_SG_adult_female_01", 
    "", 
    "None", 
    "Draft", 
    vhc_json_body)

    console.log(response);

    let projectResponse = await addVirtualHumansToProject(projectData._id, [response], id);
    console.log(projectResponse);
    projectData = projectResponse;

    let NewVirtualHumanMessage = await getVirtualHumanById(response);
    console.log(NewVirtualHumanMessage[0]);
    
    let VirtualHuman = NewVirtualHumanMessage[0];
    navigate("/createVirtualHuman", { state: { VirtualHuman,userDetails,projectData } });
  };  

  const handleSort = (option) => {    
    if (option === sortOption) {    
      setSortOption("");    
    } else {    
      setSortOption(option);    
    }    
  };    

  const onDelete = async (VirtualHumanId) => {    
    try {  
      await deleteVirtualHuman(VirtualHumanId); // delete VirtualHuman from VH collection
      await removeVirtualHumansFromProject(projectData._id, [VirtualHumanId], id); // delete VirtualHuman from Project collection
      setUpdateVirtualHumanBool(true)
    } catch (error) {  
      console.error(error);  
    }  
  };  
  return (  
    <MainContainer>
    <div id="Virtual Humans Page" className="flex-1 grid grid-cols-6 text-white">  

      <SideBar />        

      <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">  
      <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  
        Welcome {userDetails.name}, 
      </div> 
        <div className="font-light text-m text-lg my-3">What would you like to do today?</div>  
        {userDetails.role === "operator" && (  
        <button  
          className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w- my-4 hover:bg-medivrlb-600"  
          onClick={newVirtualHuman}  
        >
          Add Virtual Human
        </button> 
        )}
        <button  
          className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 my-4 hover:bg-medivrlb-600 my-3 mx-4"  
          onClick={() => navigate("/projects")}  
        >  
          Return to project  
        </button>  



        {/*  Changed text-black to text-white as shown on figma */}
        <div className="font-bold text-4xl text-white my-3 mx-4">Virtual Humans Dashboard</div>  

        {/*  
          Changed text-white to text-black as shown on figma for both btns
          Changed bg-gray-300 to bg-gray-50
        */}
        <button     
        className="bg-gray-50 font-medium rounded-full px-2 py-1 h-12 w-40 my-4 hover:bg-medivrlb-500 text-black my-3 mx-4"     
        onClick={() => handleSort("last_edited")}    
        >  
          Date Added ↑  
        </button>  
        <button       
          className="bg-gray-50 font-medium rounded-full px-2 py-1 h-12 w-40 my-4 hover:bg-medivrlb-500 text-black my-3 mx-4"       
          onClick={() => handleSort("status")}      
        >    
          Deployed   
        </button> 
{/* 
  Changes made by Sabrina:
  Changed w-75 to w-screen so the cards will not overlap (25th May, changed to max-w-md)
  remove background: "#FFFFFF" from style
*/}
        <div style={{ color: "grey", height: "600px",overflow: "auto"}} className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 rounded-md w-max">  
          <style>  
            {`  
              ::-webkit-scrollbar {  
                width: 0.3rem;  
              }  
          
              ::-webkit-scrollbar-thumb {  
                background-color: #4A5568;  
                border-radius: 9999px;  
              } 
            `}  
          </style>  

          {/*  
            Moved Virtual Humans Dashboard text, Date Added and Deployed btns out of the scrollbar
            Grid code to be added here 
            */}
          <div class="grid grid-cols-1 grid-cols-2 gap-4">
          {getVirtualHumansOutput &&      
            JSON.parse(getVirtualHumansOutput)      
              .sort((a, b) => {      
                if (sortOption === "last_edited") {      
                  return new Date(b.last_edited) - new Date(a.last_edited);      
                } else if (sortOption === "status") {      
                  if (a.status === "Draft" && b.status === "Deployed") {      
                    return 1;      
                  } else if (a.status === "Deployed" && b.status === "Draft") {      
                    return -1;      
                  } else {      
                    return 0;      
                  }      
                }      
                return 0;      
              })      
              .map((VirtualHuman) => (      
                <VirtualHumanCard key={VirtualHuman._id} VirtualHuman={VirtualHuman} userDetails={userDetails} projectData={projectData} onDelete={onDelete}/>      
              ))    
          } 
          </div> 

          {/* Grid code to be ended here */}
        </div>  
  
        {/* <div style={{ color: "black" }}>{getUserDetailsOutput}</div> */}
      </div>  
    </div>
    </MainContainer>
  );  
};  
  
export default VirtualHumansPage;  