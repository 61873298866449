import { characters } from "../data/CharacterData"
import { PutVirtualHuman } from "../api/VirtualHumans"

const CharacterSelector = ({setSelectedCharacter, previousVirtualHumanData, setPreviousVirtualHumanData}) => {

    const saveAsDraft = (character_name) => {
        let newVirtualHuman = {...previousVirtualHumanData};
        if (newVirtualHuman.character_id !== character_name.name)
        {
            newVirtualHuman.status = "Draft"
            newVirtualHuman.character_id = character_name.name
            console.log(newVirtualHuman._id, newVirtualHuman.VirtualHuman_name, newVirtualHuman.VirtualHuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            PutVirtualHuman(newVirtualHuman._id, newVirtualHuman.VirtualHuman_name, newVirtualHuman.VirtualHuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            setPreviousVirtualHumanData(newVirtualHuman);
        }
        else
        {
            console.log("No changes made")
        }
    }
    return (
        <>
            <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  
            Create Your Virtual Human  
                <div className="font-light text-3xl ml-4">  
                    - Choose Starting Look 
                </div>  
            </div> 
            <span className="inline-grid grid-cols-3 gap-10 h-96">  
                {characters.map((character, index) => (
                    <span key={index} className={`bg-gray-900/80 rounded-lg w-48 cursor-pointer hover:bg-gray-900 ${previousVirtualHumanData.character_id === character.name ? 'border-2 border-white' : ''}`} onClick={() => {setSelectedCharacter(character); saveAsDraft(character);}}>  
                        <img src={character['src']} alt="" />  
                        {previousVirtualHumanData.character_id === character.name && console.log('Border added!')}  
                    </span>  
                ))}  
            </span>  
        </>
    )
}

export default CharacterSelector