import React from 'react';  
  
const IntentAnalyticsDisplay = ({ parentGroups }) => {  
  console.log("IntentAnalyticsDisplay: parentGroups: ", parentGroups);  
  
  if (!parentGroups || parentGroups.length === 0) {  
    return <div>No Intent Analytics data available.</div>;  
  }  
  
  const calculateScore = (intents) => {  
    const totalIntents = Object.keys(intents).length;  
    const trueIntents = Object.values(intents).filter((value) => value).length;  
    return `${trueIntents}/${totalIntents}`;  
  };  
  
  return (  
    <div className="overflow-auto max-h-[700px]">  
      <table className="bg-white text-black divide-y divide-gray-200">  
        <thead className="bg-gray-300">  
          <tr>  
            <th className="px-4 py-2">Parent Group</th>  
            <th className="px-4 py-2">Intents</th>  
            <th className="px-4 py-2">Score</th>  
          </tr>  
        </thead>  
        <tbody className="divide-y divide-gray-200">  
          {parentGroups.map((group, index) => (  
            <tr key={index}>  
              <td className="px-4 py-2">{group["Parent Group"].Name}</td>  
              <td className="px-4 py-2">  
                <table className="w-full">  
                  <tbody>  
                    {Object.entries(group["Parent Group"].Intents).map(([intent, value], idx) => (  
                      <tr key={idx}>  
                        <td className="w-2/3">{intent}</td>  
                        <td className="w-1/3">  
                          <span  
                            className="inline-block w-4 h-4 rounded-full"  
                            style={{  
                              backgroundColor: value ? 'green' : 'red',  
                            }}  
                          ></span>  
                        </td>  
                      </tr>  
                    ))}  
                  </tbody>  
                </table>  
              </td>  
              <td className="px-4 py-2">{calculateScore(group["Parent Group"].Intents)}</td>  
            </tr>  
          ))}  
        </tbody>  
      </table>  
    </div>  
  );  
};  
  
export default IntentAnalyticsDisplay;  
