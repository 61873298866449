import { useMsal } from "@azure/msal-react";

const TopBar = () => {
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <div className="flex justify-between items-center mb-3">
      <span className="text-white font-semibold text-2xl">
        {/* Virtual Human Dashboard */}
      </span>
      <div>
        <span className="text-gray-300 underline decoration-gray-300">
          {/* Hello, username */}
        </span>
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginLeft: "16px" }}
          onClick={handleLogoutRedirect}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default TopBar;
