import { useState } from "react";
import speaker from '../img/speaker.png'

const VoiceTester = ({textToTest, azuresVoiceCode, language}) => {
    const [audioUrl, setAudioUrl] = useState(null);

  const fetchAudio = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("hash", process.env.REACT_APP_GENERATIONKEY);
    formData.append("text", textToTest);

    const response = await fetch(`https://azuretexttospeech_dev.picogalileoflower.xyz/TestVoiceOutput/${azuresVoiceCode}/${language}`, {
      method: "POST",
      body: formData,
    });
    const blob = await response.blob();
    setAudioUrl(URL.createObjectURL(blob));
  };

  return (
    <div>  
    <button className='px-4 py-2 bg-white text-white border-b-2 w-full' onClick={fetchAudio}>
    <img src={speaker} alt="Delete" style={{ width: "25px", height: "24px" }} />  
    </button>  
    {audioUrl && (  
      <div>  
        <audio src={audioUrl} onEnded={() => setAudioUrl(null)} autoPlay />  
        {/* <div className='flex flex-row justify-between items-center px-4 py-2 bg-blue-500 text-white'>  
          <button onClick={() => document.querySelector('audio').play()}>Play</button>  
          <button onClick={() => document.querySelector('audio').pause()}>Pause</button>  
          <button onClick={() => document.querySelector('audio').currentTime = 0}>Stop</button>  
        </div>   */}
      </div>  
    )}  
  </div>  
  );
}

export default VoiceTester