import CharacterSelector from "../../components/CharacterSelector"
import SideBar from "../../components/SideBar"

const CharacterSelectPage = ({setSelectedCharacter,setVirtualHumanInfo, previousVirtualHumanData, setPreviousVirtualHumanData}) => {
    console.log(previousVirtualHumanData)
    return (
        <div className="flex-1 grid grid-cols-6 text-white">
            <SideBar />
            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">
                <CharacterSelector 
                    setSelectedCharacter={setSelectedCharacter} 
                    previousVirtualHumanData={previousVirtualHumanData} 
                    setPreviousVirtualHumanData={setPreviousVirtualHumanData}
                /> 
            </div>

            <div className='flex justify-end'>
                <div>
                    <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-medivrlb-600' onClick={() =>{ setSelectedCharacter(null); setVirtualHumanInfo(null);}}>Back</button>
                </div>
            </div>
        </div>
    )
}

export default CharacterSelectPage