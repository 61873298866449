export const getLanguages = () => {
    return [...new Set(languageVoiceData.map(voice => voice.language))]
}

export const getVoiceAccent = (language) => {
    return languageVoiceData.filter(voice => voice['language'] === language)
}

export const getVoiceAccents = (language, gender = null) => {  
    return [  
      ...new Set(  
        languageVoiceData  
          .filter(  
            (voice) =>  
              voice["language"] === language &&  
              (gender === null || voice["gender"] === gender)  
          )  
          .map((voice) => voice.VoiceId)  
      ),  
    ];  
  };  

export const getAzureVoiceIdsFromLang = (language, gender = null) => {  
    return [  
      ...new Set(  
        languageVoiceData  
          .filter(  
            (voice) =>  
              voice["language"] === language &&  
              (gender === null || voice["gender"] === gender)  
          )  
          .map((voice) => voice.azuresVoiceCode)  
      ),  
    ];  
  }; 


export const getLanguageFromVoiceId = (voiceId) => {  
    const voice = languageVoiceData.find((voice) => voice.VoiceId === voiceId);  
    return voice ? voice.language : null;  
  };  
export const languageVoiceData = [
    {
        VoiceId: 'chinese_SG_adult_male_01',
        azuresVoiceCode: 'WayneNeural',
        language: 'en-SG',
        gender: 'male',
    },
    {
        VoiceId: 'chinese_HK_adult_male_01',
        azuresVoiceCode: 'SamNeural',
        language: 'en-HK',
        gender: 'male',
    },
    {
        VoiceId: 'chinese_CN_adult_male_01',
        azuresVoiceCode: 'YunyeNeural',
        language: 'zh-CN',
        gender: 'male',
    },
    {
        VoiceId: 'chinese_SG_adult_female_01',
        azuresVoiceCode: 'LunaNeural',
        language: 'en-SG',
        gender: 'female',
    },
    {
        VoiceId: 'chinese_HK_adult_female_01',
        azuresVoiceCode: 'YanNeural',
        language: 'en-HK',
        gender: 'female',
    },
    {
        VoiceId: 'chinese_CN_adult_female_01',
        azuresVoiceCode: 'XiaozhenNeural',
        language: 'zh-CN',
        gender: 'female',
    },
    {
        VoiceId: 'indian_SG_adult_male_01',
        azuresVoiceCode: 'AnbuNeural',
        language: 'ta-SG',
        gender: 'male',
    },
    {
        VoiceId: 'indian_MY_adult_male_01',
        azuresVoiceCode: 'SuryaNeural',
        language: 'ta-MY',
        gender: 'male',
    },
    {
        VoiceId: 'indian_IN_adult_male_01',
        azuresVoiceCode: 'ValluvarNeural',
        language: 'ta-IN',
        gender: 'male',
    },
    {
        VoiceId: 'indian_SG_adult_female_01',
        azuresVoiceCode: 'VenbaNeural',
        language: 'ta-SG',
        gender: 'female',
    },
    {
        VoiceId: 'indian_MY_adult_female_01',
        azuresVoiceCode: 'KaniNeural',
        language: 'ta-MY',
        gender: 'female',
    },
    {
        VoiceId: 'indian_IN_adult_female_01',
        azuresVoiceCode: 'PallaviNeural',
        language: 'ta-IN',
        gender: 'female',
    },
    {
        VoiceId: 'malay_MY_adult_male_01',
        azuresVoiceCode: 'JamesNeural',
        language: 'en-MY',
        gender: 'male',
    },
    {
        VoiceId: 'malay_MY_adult_female_01',
        azuresVoiceCode: 'RosaNeural',
        language: 'en-MY',
        gender: 'female',
    },
    {
        VoiceId: 'malay_MY_elderly_male_01',
        azuresVoiceCode: 'JamesNeural',
        language: 'en-MY',
        gender: 'male',
    },
    {
        VoiceId: 'malay_MY_elderly_female_01',
        azuresVoiceCode: 'RosaNeural',
        language: 'en-MY',
        gender: 'female',
    },
    {
        VoiceId: 'caucasian_UK_adult_male_01',
        azuresVoiceCode: 'OliverNeural',
        language: 'en-GB',
        gender: 'male',
    },
    {
        VoiceId: 'caucasian_AU_adult_male_01',
        azuresVoiceCode: 'DarrenNeural',
        language: 'en-AU',
        gender: 'male',
    },
    {
        VoiceId: 'caucasian_US_adult_male_01',
        azuresVoiceCode: 'GuyNeural',
        language: 'en-US',
        gender: 'male',
    },
    {
        VoiceId: 'caucasian_UK_adult_female_01',
        azuresVoiceCode: 'AbbiNeural',
        language: 'en-GB',
        gender: 'female',
    },
    {
        VoiceId: 'caucasian_AU_adult_female_01',
        azuresVoiceCode: 'JoanneNeural',
        language: 'en-AU',
        gender: 'female',
    },
    {
        VoiceId: 'caucasian_US_adult_female_01',
        azuresVoiceCode: 'SaraNeural',
        language: 'en-US',
        gender: 'female',
    },
]
