import { useEffect, useState } from "react"
import { uploadImage } from "../../api/ImageUploader"
import SideBar from "../../components/SideBar"
import VoiceTester from "../../components/VoiceTester"
import { getLanguages, languageVoiceData ,getLanguageFromVoiceId, getVoiceAccents, getAzureVoiceIdsFromLang} from "../../data/LanguageVoiceData"
import { PutVirtualHuman } from "../../api/VirtualHumans"
import { getCharacterByName } from "../../data/CharacterData"

const VoiceSelectPage = ({ selectedCharacter, setSelectedCharacter, previousVirtualHumanData, setPreviousVirtualHumanData, confirmVoiceSettings }) => {

    const [textToTest, setTextToTest] = useState('')
    const [language, setLanguage] = useState('en-SG')
    const [voice, setVoice] = useState(selectedCharacter)
    const [bgImgSrc, setBgImgSrc] = useState(null)
    const [isBgImgUploading, setIsBgImgUploading] = useState(false)
    const [voiceForTesting, setVoiceForTesting] = useState(null)
    const [languageForTesting, setLanguageForTesting] = useState(null)  

    const [draftSaved, setDraftSaved] = useState(false);   
    const [changesMade, setChangesMade] = useState(false);  

    console.log(previousVirtualHumanData)

    useEffect(() => {
        if (previousVirtualHumanData.background_url !== undefined) { // If there is a previous VirtualHuman with a background image
        setBgImgToPrevious()
        }
        if (previousVirtualHumanData.voice_id !== undefined) { // If there is a previous VirtualHuman with a voice
            console.log("Previous voice id: ", previousVirtualHumanData.voice_id)
            console.log(getLanguageFromVoiceId(previousVirtualHumanData.voice_id))
            setLanguage(getLanguageFromVoiceId(previousVirtualHumanData.voice_id))
            setVoice(languageVoiceData  
                .find(voice => voice['VoiceId'] === previousVirtualHumanData.voice_id))
        }
        else
        {
            console.log(selectedCharacter)
            if (selectedCharacter) {
                if (selectedCharacter['gender'] === 'male') {
                    setVoice(languageVoiceData[0])
                } else {
                    setVoice(languageVoiceData[3])
                }
            }
        }

    }, [selectedCharacter])

    useEffect(() => {
        
        setVoice(languageVoiceData
            .filter(voice => voice['language'] === language)
            .filter(voice => selectedCharacter && selectedCharacter['gender'] === voice['gender'])[0])
    }, [language])

    useEffect(() => { // When the language changes, set the voice for testing purposes to the first voice in the list
        let voiceDisplayed = getAzureVoiceIdsFromLang(language,selectedCharacter ? selectedCharacter["gender"] : null)[0]
        setLanguageForTesting(language)
        setVoiceForTesting(voiceDisplayed)
    }, [language])
    useEffect(() => {// When the voice is changed by selecting from dropdown list, set the voice for testing purposes to the selected voice
        let voiceDisplayed = voice['azuresVoiceCode']
        setLanguageForTesting(voice['language'])
        setVoiceForTesting(voiceDisplayed)
    }, [voice])


    useEffect(() => {
        if (bgImgSrc) {
            setIsBgImgUploading(false)
        }
    }, [bgImgSrc])

    const onLanguageSelectChange = e => {
        setLanguage(e.target.value)
        setChangesMade(true);
    }
    const onVoiceSelectChange = e => {
        console.log("Check this one out: ", e.target.value)
        setVoice(languageVoiceData
            .find(voice => voice['VoiceId'] === e.target.value))
        setChangesMade(true);
    }

    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg)$/i;  

    const onUploadSubmit = async e => {
        e.preventDefault()

        const file = e.target.elements.upload.files[0];  
        const fileName = file.name;  
        console.log(fileName)
        const isValidFile = allowedExtensions.test(fileName); 
        if (isValidFile === false) {  
            setIsBgImgUploading(false)
            return
        }

        if (e.target.upload.files.length > 0) {
            setIsBgImgUploading(true)
            const resp = await uploadImage(e.target.upload.files[0])
            const isSuccess = resp['data']['success']
            if (isSuccess) {
                const src = resp['data']['result']['variants'][0]
                setBgImgSrc(src)
                setChangesMade(true);
            } else {
                setIsBgImgUploading(false)
            }
        }
    }

    const resetBgImg = () => {
        setBgImgSrc(null)
    }

    const setBgImgToPrevious = () => {
        setBgImgSrc(previousVirtualHumanData.background_url)
    }

    const saveAsDraft = () => {
        console.log("the new voice is: ", voice.VoiceId)
        console.log(previousVirtualHumanData)
        let newVirtualHuman = {...previousVirtualHumanData}; // create a copy of the original VirtualHuman object
        console.log(previousVirtualHumanData._id)
        console.log(newVirtualHuman._id)
        if (newVirtualHuman.voice_id !== voice.VoiceId || newVirtualHuman.background_url !== bgImgSrc)
        {
            newVirtualHuman.background_url = bgImgSrc;
            console.log(voice.VoiceId)
            newVirtualHuman.voice_id = voice.VoiceId;
            newVirtualHuman.status = "Draft"
            console.log(newVirtualHuman._id)
            console.log(newVirtualHuman._id, newVirtualHuman.virtualhuman_name, newVirtualHuman.virtualhuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            PutVirtualHuman(newVirtualHuman._id, newVirtualHuman.virtualhuman_name, newVirtualHuman.virtualhuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            
            setDraftSaved(true);  
            setChangesMade(false);
            setTimeout(() => {  
            setDraftSaved(false);
            setSelectedCharacter(getCharacterByName(newVirtualHuman.character_id))
            setPreviousVirtualHumanData(newVirtualHuman)
            }, 1000);  // Change the button to "Saved" for 3 seconds
        }
        else
        {
            console.log("No changes made")
        }

    }

    const buttonClass = `font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-blue-900 ${  
        changesMade ? "bg-orange-500" : "bg-gray-700"  
      } ${draftSaved ? "bg-green-500" : ""}`;  

    return (
        <div id='language' className="flex-1 grid grid-cols-6 text-white">
            <SideBar />
            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">
                <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  
                Create Your Virtual Human  
                    <div className="font-light text-3xl ml-4">  
                        - Appearance
                    </div>  
                </div> 
                <div className="h-96">
                    <div className="my-4">
                        <div className='py-2'>
                            
                            <div className='flex flex-row justify-between items-center'>  
                                <div className='w-1/3'>  
                                    <label htmlFor='vh_language' className='text-white'>  
                                    Language:  
                                    </label>  
                                </div>  
                                <div className='w-2/3'>  
                                    <select  
                                    name='vh_language'  
                                    id='vh_language'  
                                    // style={{ marginLeft: '-200px' }}  
                                    className='px-4 py-2 border rounded-lg bg-white text-black border-b-2 w-full ml-[-200px]'  
                                    onChange={onLanguageSelectChange}  
                                    value={language}  
                                    >  
                                    {getLanguages().map((option, index) => (  
                                        <option className='text-black' key={index} value={option}>  
                                        {option}  
                                        </option>  
                                    ))}  
                                    </select>  
                                </div>  
                            </div>  
                        </div>


                        <div className='py-2'>
                            
                            <div className='flex flex-row justify-between items-center'>  
                                <div className='w-1/3'>  
                                    <label htmlFor='vh_language' className='text-white'>  
                                    Voice & Accent:  
                                    </label>  
                                </div>  
                                <div className="w-2/3">  
                                    <select  
                                    name="vh_language"  
                                    id="vh_language"  
                                    className="px-4 py-2 border rounded-lg bg-white text-black border-b-2 w-full ml-[-200px]"  
                                    onChange={onVoiceSelectChange}  
                                    value={voice.VoiceId}  
                                    >  
                                    {getVoiceAccents(  
                                    language,  
                                    selectedCharacter ? selectedCharacter["gender"] : null  
                                    ).map((accent, index) => (  
                                    <option key={index} className="text-black" value={accent}>  
                                        {accent}  
                                    </option>  
                                    ))}  
                                    </select>  
                                </div>  

                            </div>  
                        </div>

                        <div className='py-2'>  
                            <div className='flex flex-row justify-between items-center'>  
                                <div className='w-1/3'>  
                                <label htmlFor='vh_language' className='text-white'>  
                                    Test how it sounds:  
                                </label>  
                                </div>  
                                <div className='w-2/3 flex items-center'>  
                                    <input  
                                        value={textToTest}  
                                        onChange={e => setTextToTest(e.target.value)}  
                                        type='text'  
                                        placeholder='Input text here'  
                                        className='px-4 py-2 bg-white text-black border-b-2 w-[90%] ml-[-200px]'  
                                    />  
                                {/* <button className='px-4 py-2 bg-blue-500 text-white border-b-2 w-[10%]'>  
                                    Test  
                                </button>   */}
                                    <VoiceTester
                                    textToTest={textToTest}
                                    // azuresVoiceCode={voice['azuresVoiceCode']}
                                    // language={voice['language']} 
                                    azuresVoiceCode={voiceForTesting}
                                    language={languageForTesting}
                                    />

                                </div>  
                            </div>  
                        </div>  
                    </div>

                    <div className="my-4">
                        {/* <div className='font-medium text-lg'>What is the virtual background for your virtual human?</div> */}

                        <div className='py-2'>
                            <div className='font-medium'>Upload a virtual background</div>
                            {bgImgSrc ? (
                                <div>
                                    <img src={bgImgSrc} class="h-36 w-64 border object-cover rounded" />
                                    <button className='bg-gray-900 font-medium rounded-full px-2 py-1 my-4 hover:bg-blue-900' onClick={resetBgImg}>Reupload</button>
                                </div>
                            ) : (
                                isBgImgUploading ? (
                                    <div class="animate-pulse h-36 w-64 bg-gray-500 rounded "></div>
                                ) : (
                                    <form onSubmit={onUploadSubmit}>
                                        <input class="px-4 py-2 bg-transparent border-b-2 w-1/2" name='upload' id="file_input" type="file" />
                                        <div className='text-xs text-gray-400 italic'>*Please ensure your image is of 16:9 aspect ratio and no bigger than 10MB</div>
                                        <input type='submit' className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 my-4 hover:bg-medivrlb-600' value='Upload' />
                                    </form>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-end'>
                <div>
                    <img className='bg-gray-900/80 rounded-lg w-48' src={selectedCharacter && selectedCharacter['src']} alt="" />
                    <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600' onClick={() => {setSelectedCharacter(null); saveAsDraft();}}>Back</button>
                    <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600' onClick={() => {confirmVoiceSettings({voice, bgImgSrc}); saveAsDraft();}}>Next</button>
                    <button   
                    className={buttonClass}
                    onClick={saveAsDraft}>  
                    {draftSaved ? 'Draft Saved' : 'Save as Draft'}  
                </button>  
                </div>
            </div>
        </div>
    )
}

export default VoiceSelectPage