const vhcJsonBodyData = {  
    "response": {  
      "data": {  
        "UID": "",  
        "role1": "",  
        "role2": "",  
        "Convo Background": "",
        "Convo Mood": "",
        "Name": "",  
        "NRIC": "",  
        "Date of Birth": "",  
        "Age": "",  
        "Gender": "",  
        "Occupation": "",  
        "Ethnicity": "",  
        "Nationality": "",  
        "Religion": "",   
        "Income": "",  
        "Height": "",  
        "Weight": "",  
        "Case Note": "",  
        "phases": [
        ]  
      }  
    }  
  };  
    
  export default JSON.stringify(vhcJsonBodyData);  
  