import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar"
import UserInputField from "../../components/UserInputField"
import { formQuestions } from "../../data/CreationFormData"
import { PutVirtualHuman } from "../../api/VirtualHumans";

const VirtualHumanInformationPage = ({
    previousVirtualHumanData,
    setPreviousVirtualHumanData,
    setVirtualHumanInfo,
    projectData,
    userDetails,

    vhSceneName,
    setVhSceneName,

    vhSceneDescription,
    setVhSceneDescription,


}) => {

    const navigate = useNavigate();

    const [changesMade, setChangesMade] = useState(false);
    const [VirtualHumanName, setVirtualHumanName] = useState(null)
    const [VirtualHumanDescription, setVirtualHumanDescription] = useState(null)

    useEffect(() => {

        if (vhSceneName !== null){
            setVirtualHumanName(vhSceneName)
        }
        else if (previousVirtualHumanData.virtualhuman_name !== null)
        {
            setVirtualHumanName(previousVirtualHumanData.virtualhuman_name)
        }

        if (vhSceneDescription !== null){
            setVirtualHumanDescription(vhSceneDescription)
        }
        else if (previousVirtualHumanData.virtualhuman_description !== null)
        {
            setVirtualHumanDescription(previousVirtualHumanData.virtualhuman_description)
        }


    }, [])



    const saveAsDraft = () => {
        if (changesMade === true)
        {
            let newVirtualHuman = {...previousVirtualHumanData};
            newVirtualHuman.status = "Draft"
            newVirtualHuman.virtualhuman_name = VirtualHumanName;
            newVirtualHuman.virtualhuman_description = VirtualHumanDescription;
            PutVirtualHuman(newVirtualHuman._id, newVirtualHuman.virtualhuman_name, newVirtualHuman.virtualhuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            setPreviousVirtualHumanData(newVirtualHuman);
            console.log("Draft is saved because changedMade === true. VHInformationPage: the newvirtualhuman is: ", newVirtualHuman)
            
            setChangesMade(false);
        }
        else
        {
            console.log("No changes made.")
            console.log("vhSceneName", vhSceneName)
            console.log("vhSceneDescription", vhSceneDescription)
            console.log("virtualhuman is now:", previousVirtualHumanData)
        }
    }

      
      

    const handleVirtualHumanDescriptionChange = (e) => {
        // console.log(e.target.value)
        setVirtualHumanDescription(e.target.value)
        setVhSceneDescription(e.target.value)
        setChangesMade(true)
    }
    const handleVirtualHumanNameChange = (e) => {
        // console.log(e.target.value)
        setVirtualHumanName(e.target.value)
        setVhSceneName(e.target.value)
        setChangesMade(true)
    }

    // const buttonClass = `bg-gray-700 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-blue-900 ${changesMade ? 'bg-orange-500' : ''} ${draftSaved ? 'bg-green-500' : ''}`;  
    
    return (  
        <div id="Virtual Human Information" className="flex-1 grid grid-cols-6 text-white text-left">  
            <SideBar />  
            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">  
            <div className="font-bold text-3xl flex items-center">  
            Create Your Virtual Human
                <div className="font-light text-3xl ml-4">  
                    - Name And Description  
                </div>  
            </div>   
                <form>  
                    <div className="my-4 flex flex-row items-center">  
                        <label htmlFor="VirtualHumanName" className="block font-medium mb-2 w-32 mr-4 ">Scenario Name</label>  
                        <input type="text" id="VirtualHumanName" className="border rounded-lg py-2 px-3 w-full" style={{ color: 'black' }} defaultValue={VirtualHumanName} onChange={handleVirtualHumanNameChange}/>  
                    </div>  
                    <div className="my-4 flex flex-row items-center">  
                            <label htmlFor="VirtualHumanDescription" className="block font-medium mb-2 w-32 mr-4">Scenario Description</label>  
                            <textarea id="VirtualHumanDescription" rows="4" className="border rounded-lg py-2 px-3 w-full" style={{ color: 'black' }} defaultValue={VirtualHumanDescription} onChange={handleVirtualHumanDescriptionChange}></textarea>  
                    </div>  
                    <div className="my-4 flex flex-row justify-between">  
                        <button  
                            className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-medivrlb-600"  
                            onClick={() => {  
                                navigate("/virtualhumans", {  
                                    state: { projectData: projectData, userDetails: userDetails },  
                                    });  
                                if (changesMade) {  
                                saveAsDraft();  
                                }  
                            }}  
                            >  
                            Back  
                        </button>  
                        <div className="flex justify-end">  
                            <button  
                                className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-medivrlb-600"  
                                onClick={() => {  
                                setVirtualHumanInfo(true);  
                                // if (changesMade) {  
                                //     saveAsDraft();  
                                // }  
                                saveAsDraft();
                                }}  
                            >  
                                Next  
                            </button>  
                        </div>  
                    </div>  
                </form>  
            </div>  
        </div>  
    )  
}

export default VirtualHumanInformationPage