import React, { useState, useEffect, useRef } from "react";  
import { Container } from "reactstrap";  
import { getTokenOrRefresh } from "..//token_util";  
import "../custom.css";  
import {  
  PropertyId,  
  ResultReason,  
  User,  
} from "microsoft-cognitiveservices-speech-sdk";  
import {  
  Player,  
  FS_SDK_EVENTS_NAME,  
  FS_QUALITY_VALUES,  
} from "furioos-sdk";  
import PopUp from "../components/popup";  
import { startTimer, stopTimer } from "../api/UserDetails";  
import { useMsal } from "@azure/msal-react";  

import { useNavigate, useLocation } from "react-router-dom";

// import { initializeSlots, getLongestRemainingToken, stopRefreshingTokens, resetTokens } from "../get_longest_remaining_token";

  
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");  
  
const PlayPage = (props) => {  
  const [isOpen, setIsOpen] = useState(false);  
  const recognizerRef = useRef(null);  
  const audioContextRef = useRef(null);  
  const isSessionConfigSentRef = useRef(false);  
  const playerRef = useRef(null); 
  const [ playerLoaded, setPlayerLoaded ] = useState(false);
  const [ playerStarted, setPlayerStarted ] = useState(false);
  const [ isQueuing, setIsQueuing ] = useState(false);
  const [ AppStarted, setAppStarted ] = useState(false);
  const [queueCountdown, setQueueCountdown] = useState(null); 
  const [TimeToAssignSession, setTimeToAssignSession] = useState(null);

  const [canSendSTTMessage , setCanSendSTTMessage ] = useState(true);  

  const location = useLocation();
  const locIdProp = location.state?.IdProp;
  const locCharacterId = location.state?.CharacterId;
  const locConvoId = location.state?.ConvoId;
  const locVoiceId = location.state?.VoiceId;
  const locCustomImageId = location.state?.CustomImageId;
  const locVirtualHumanId = location.state?.VirtualHumanId;


  const [whitePanelVisible, setWhitePanelVisible] = useState(true);  
  

  //console.log("locIdProp, locCharacterId, locConvoId, locVoiceId, locCustomImageId, locVirtualHumanId: " , locIdProp, locCharacterId, locConvoId, locVoiceId, locCustomImageId, locVirtualHumanId)
  const togglePopup = () => {  
    setIsOpen((prevState) => !prevState);  
  };  
  
  const showRestartSessionPopup = () => {  
    setIsOpen(true);  
  };  
  const hideRestartSessionPopup = () => {  
    setIsOpen(false);  
  };  


  const stopAndStartPlayer = async () => {  
    // Stop the player  
    console.log("player stop()")
    playerRef.current.stop();
    
    // Wait for a specific duration (e.g., 2000 ms) before starting the player  
    await new Promise((resolve) => setTimeout(resolve, 2000));  
    console.log("player start()")
    // Start the player  
    playerRef.current.start();  
  };  




  const StartPlayer = async () => {  
    setPlayerStarted(true);
    if (playerLoaded === true) {
      console.log("Check if need to queue for a server");
      getavail();
      // Start the player  
      playerRef.current.start();  
      setPlayerStarted(true);
    }
    else{
      console.error("player not loaded! Cannot start yet!")
    }
  };
  const StopPlayer = async () => {  
    if (playerLoaded === true) {
      // Stop the player  
      console.log("player stop()")
      playerRef.current.stop();
    }
    else{
      console.error("player not loaded! Cannot stop!")
    }
  };



  const getavail = async () => {  
    console.log("testing")


    playerRef.current.getServerAvailability(function(data) {
      console.log("Time to assign a server: ", data.assignTime);
      if (data.assignTime > 0) {
        console.log("Have to queue for a server");
        setIsQueuing(true);
        setTimeToAssignSession(data.assignTime * 60 + 45);
        setQueueCountdown(data.assignTime * 60 + 45);  
      }
      else {
        console.log("No need to queue for a server");
        setIsQueuing(false);
      }
      // console.log("Time to copy, extract and launch your application: ", data.launchTime);
      // console.log("Number of machines ready for a session: ", data.availableMachines);
      // console.log("Total time to get session ready: ", data.assignTime + data.launchTime);
    }); 
  };  

  useEffect(() => {  
    if (queueCountdown === null || queueCountdown  <= 0) return;  
    
    const countdownInterval = setInterval(() => {  
      setQueueCountdown((prevCountdown) => prevCountdown - 1);  
    }, 1000);  
    
    return () => {  
      clearInterval(countdownInterval);  
    };  
  }, [queueCountdown]);  
  
  useEffect(() => {  
    async function componentDidMount() {  
      console.log(  
        ">>>>>>>>>>>>>>>>>> Version Number: 1.2.0 1/6/2023 <<<<<<<<<<<<<<<<<<<<<<<<"  
      );  
  
      checkForMicrophonePermission();  
  
      
      const options = {  
        whiteLabel: true,  
        hideToolbar: false,  
        hideTitle: true,  
        hidePlayButton: false,  
        debugAppMode: false,  
        //inactiveTimeout: 60000,  
        inactiveTimeout: 1800000,  
      };  
  
      playerRef.current = new Player(  
        process.env.REACT_APP_SDKLINKID,  
        "furioos_container",  
        options  
      );  

      // Bind player loaded  
      playerRef.current.on(FS_SDK_EVENTS_NAME.LOAD, () => {  
        console.log("SDK client FIRED: Player loaded");  
        setPlayerLoaded(true);
      });  

      playerRef.current.getServerAvailability(function(data) {
        console.log("Time to assign a server: ", data.assignTime);
        console.log("Time to copy, extract and launch your application: ", data.launchTime);
        console.log("Number of machines ready for a session: ", data.availableMachines);
        console.log("Total time to get session ready: ", data.assignTime + data.launchTime);
      }, function(error) {
        // Treat the error.
      });
  
      // Bind application install progress  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS, (data) => {  
        console.log("SDK client FIRED: App install progress", data);  
      });  
      // Bind application install progress  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ERROR, (data) => {  
        console.log("SDK client FIRED: Error!", data);  
      });  
    
      // Bind application start  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_APP_START, () => {  
        console.log(  
          "SDK client FIRED: App start. Setting quality settings to AUTO"  
        );  
        if (AppStarted === false) {
          setAppStarted(true);
          console.log("AppStarted: " + AppStarted);
        }
        playerRef.current.setQuality(FS_QUALITY_VALUES.AUTO);  
        console.log(  
          "current Quality settings is: " + playerRef.current.getQuality()  
        ); // for testing  
      });  
  
      // Bind stream start  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_STREAM_START, () => {  
        console.log("SDK client FIRED: Stream start");  
        streamStarted = true; // for use by restart pop up logic, under stats checker.  
        startTimer(props.location.state.IdProp); // start the stream timer for the user  
      });  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_STREAM_STOPPED, () => {  
        console.log("SDK client FIRED: Stream stopped");  
        streamStarted = false; // for use by restart pop up logic, under stats checker.  
        stopTimer(props.location.state.IdProp); // stop the stream timer for the user  
      });  
      
  
      // Bind stream start  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_SDK_START, () => {  
        console.log("SDK client FIRED: SDK start");  
        //NOTE: Just for lien  
        sendSessionConfig();  
        setWhitePanelVisible(false);
      });  
  
      // Bind SDK messages  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, (data) => {  
        console.log("SDK Message Received:", data);  
  
        const action = data.action;  
        if (action === "mute") {  
          console.log("Received mute action");  
          stopSpeechRecognition();  
        }  
        if (action === "unmute") {  
          console.log("Received unmute action");  
          startSpeechRecognition();  
        }  
  
        if (data.includes("unmute")) {  
          console.log("unmuting!");  
          startSpeechRecognition();  
        } else if (data.includes("mute")) {  
          console.log("muting!");  
          stopSpeechRecognition();  
        }  
      });  
  
      // Bind an event that lets you know if you can resume session  
      playerRef.current.on(  
        FS_SDK_EVENTS_NAME.ON_RESUME_SESSION,  
        ({ canResumeSession }) => {  
          if (canResumeSession) {  
            console.log("SDK client FIRED: Can resume session. DO NOT RESUME SESSION! Instead, try stopping it first.");
            playerRef.current.stop();
          }  
        }  
      ); 
  
      // Bind session stoppeds  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED, () => {  
        console.log("SDK client FIRED: Session Stopped");  
        stopSpeechRecognition();  
        streamStarted = false;  
        stopTimer(props.location.state.IdProp); // stop the stream timer for the user  
      });  
  
      let restartSessionTimeout;  
      let streamStarted = false;  
  
      playerRef.current.on(FS_SDK_EVENTS_NAME.ON_STATS, (stats) => {  
        console.log(`The current framerate is: ${stats.videoFramerate}`);  
  
        // if stream is broken, ie: stream is at 0 fps.  
        if (stats.videoFramerate <= 1 && streamStarted) {  
          if (!restartSessionTimeout) {  
            restartSessionTimeout = setTimeout(() => {  
              restartSessionTimeout = null;  
              showRestartSessionPopup();  
            }, 20000);  
          }  
        } else {  
          if (restartSessionTimeout) {  
            clearTimeout(restartSessionTimeout);  
            restartSessionTimeout = null;  
          }  
        }  
      });  
    }  
    // Start the audio context when the component mounts  
    startAudioContext();  
    componentDidMount();  
  
    return () => {  
      try{      
        stopSpeechRecognition();
        stopAudioContext();
        console.log("refreshing or exiting webpage. Stopping Player");  
        playerRef.current.stop();  
      }
      catch(err){
        console.log(err);
      }

      // Remove player loaded event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.LOAD);  
  
      // Remove application install progress event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS);  
  
      // Remove application start event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_APP_START);  
  
      // Remove stream start event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_STREAM_START);  
  
      // Remove SDK start event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_SDK_START);  
  
      // Remove SDK message event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE);  
  
      // Remove resume session event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION);  
  
      // Remove session stopped event listener  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED);  
  
      playerRef.current.off(FS_SDK_EVENTS_NAME.ON_STATS);  


      stopTimer(props.location.state.IdProp); // stop the stream timer for the user
    };  
  }, []);   
  
  //TODO: Check if constantly calling this will cause any issues  
  const startAudioContext = () => {  
    console.log("starting audio context");  
    // Create an AudioContext  
    audioContextRef.current = new AudioContext();  
    // Start the audio context  
    audioContextRef.current.resume();  
  };  

  const stopAudioContext = () => {  
    console.log("stopping audio context");
    if (audioContextRef.current) {  
      audioContextRef.current.close();  
    }  
  }; 
  
  const checkForMicrophonePermission = () => {  
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {  
      navigator.mediaDevices  
        .getUserMedia({ audio: true })  
        .catch((error) => {  
          console.log("Error getting microphone access: " + error);  
        });  
    } else {  
      console.log("getUserMedia not supported on this browser");  
    }  
  };  
  
  const sendSessionConfig = async () => {  
    console.log("session config not set. setting now.");  
    const params = new URLSearchParams(window.location.search);  
  
    const getUserId = () => {  
      const userId = params.get("UserId");  
      return userId !== null ? userId : locIdProp;  
    };  
  
    const getCharacterId = () => {  
      const characterId = params.get("CharacterId");  
      return characterId !== null  
        ? characterId  
        : locCharacterId;  
    };  
  
    const getConvoId = () => {  
      const convoId = params.get("ConvoId");  
      return convoId !== null ? convoId : locConvoId;  
    };  
  
    const getMoodId = () => {  
      const moodId = params.get("MoodId");  
      return moodId !== null  
        ? moodId  
        : null  
    };  
  
    const getVoiceId = () => {  
      const voiceId = params.get("VoiceId");  
      return voiceId !== null ? voiceId : locVoiceId;  
    };  
  
    const getCustomImageId = () => {  
      const customImageId = params.get("CustomImageId");  
      return customImageId !== null  
        ? customImageId  
        : locCustomImageId;  
    };  
    const getVirtualHumanId = () => {  
      const virtualHumanId = params.get("VirtualHumanId");  
      return virtualHumanId !== null  
        ? virtualHumanId  
        : locVirtualHumanId;  
    };  
    
  
    const UserId = getUserId();  
    const CharacterId = getCharacterId();  
    const ConvoId = getConvoId();  
    const MoodId = getMoodId();  
    const VoiceId = getVoiceId();  
    const CustomImageId = getCustomImageId();  
    const VirtualHumanId = getVirtualHumanId();  
  
    let Environment = process.env.REACT_APP_UNITYENVIRONMENT;
  
    console.log(  
      "SessionConfig: UserId: " +  
        UserId +  
        ", CharacterId: " +  
        CharacterId +  
        ", ConvoId: " +  
        ConvoId +  
        ", VoiceId: " +  
        VoiceId +  
        ", CustomImageId: " +  
        CustomImageId +  
        ", Environment: " +  
        Environment +  
        ", VirtualHumanId: " +  
        VirtualHumanId  
    );  
    const message =  
      UserId +  
      "|" +  
      CharacterId +  
      "|" +  
      ConvoId +  
      "|" +  
      MoodId +  
      "|" +  
      VoiceId +  
      "|" +  
      CustomImageId +  
      "|" +  
      Environment +  
      "|" +  
      VirtualHumanId;  
    console.log("message to send: " + message);  
    await sendFurioosMessage("SessionConfig", message);  
    isSessionConfigSentRef.current = true;  
  };  
  
  const sendFurioosMessage = async (status, message) => {  
    //console.log("SDK Example: Call sendSDKMessage", new Date());  
    if (status === "Recognised")
    {
      //check if can send STT message
      if (canSendSTTMessage === false)
      {
        console.log("STT message not sent as previous STT message is still processing");
        return;
      }
      else
      {
        playerRef.current.sendSDKMessage({  
          status: status,  
          message: message,  
        });
        setCanSendSTTMessage(false);
        setTimeout(() => {  
          setCanSendSTTMessage(true);
        }, 1000); // set timeout to 1 second
        return;
      }
    }
    playerRef.current.sendSDKMessage({  
      status: status,  
      message: message,  
    });  
  };  
  
  const restartFurioosSession = async () => {  
    console.log("restart furioos stream.");  
    playerRef.current.restartStream();  
    hideRestartSessionPopup();  
  };  
  
  const startSpeechRecognition = async () => {  
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(  
      tokenObj.authToken,  
      tokenObj.region  
    );   
  
    // https://learn.microsoft.com/en-gb/azure/cognitive-services/speech-service/how-to-recognize-speech?pivots=programming-language-csharp#change-how-silence-is-handled  
    // speechConfig.setProperty(PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "1000")  
    // speechConfig.setProperty(PropertyId.Speech_SegmentationSilenceTimeoutMs, "750")  
  
    speechConfig.speechRecognitionLanguage = "en-US";  
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();  
    if (recognizerRef.current === null) {  
      recognizerRef.current = new speechsdk.SpeechRecognizer(  
        speechConfig,  
        audioConfig  
      );  
    } else {  
      console.log(  
        "There is already a recognizer within the session. Aborting startSpeechRec"  
      );  
      return;  
    }  
  
    recognizerRef.current.startContinuousRecognitionAsync();  
  
    recognizerRef.current.recognizing = (  
      sender,  
      recognitionEventArgs  
    ) => {  
      if (  
        recognitionEventArgs &&  
        recognitionEventArgs.result &&  
        recognitionEventArgs.result.text  
      ) {  
        var result = recognitionEventArgs.result;  
        sendFurioosMessage("Recognising", result.text);  
      }  
    };  
    recognizerRef.current.canceled = (sender, cancellationEventArgs) => {  
      console.log("cancelled!");  
      console.log(cancellationEventArgs);  

      console.log("Connection failure. Attempting to reconnect...");  
      // Stop the current recognition before attempting to reconnect  

      stopSpeechRecognition();  
      // Call the startSpeechRecognition function to restart the process  
      startSpeechRecognition();  
    };  
  
    recognizerRef.current.sessionStarted = (sender, sessionEventArgs) => {  
      console.log("Session started" + sessionEventArgs.sessionId);  
    };  
  
    recognizerRef.current.sessionStopped = (sender, sessionEventArgs) => {  
      console.log("Session stopped" + sessionEventArgs.sessionId);  
    };  
  
    recognizerRef.current.recognized = (sender, recognitionEventArgs) => {  
      if (  
        recognitionEventArgs &&  
        recognitionEventArgs.result &&  
        recognitionEventArgs.result.text  
      ) {  
        var result = recognitionEventArgs.result;  
        console.log("recognised" + result.text);  
        sendFurioosMessage("Recognised", result.text);  
        // Stops multiple responses from triggering  
        stopSpeechRecognition();  
      }  
    };  
  };  
  
  const stopSpeechRecognition = () => {  
    console.log("stopping speech recognition")
    if (recognizerRef.current != null) {  
      recognizerRef.current.stopContinuousRecognitionAsync(  
        () => {  
          console.log("Speech recognition stopped");  
          recognizerRef.current = null;  
        },  
        (error) => {  
          console.log(error);  
        }  
      );  
    } else {  
      console.log(  
        "There is currently no speech recognizer! Aborting stop speech rec."  
      );  
    }  
  };  
  
  return (  
    <Container className="app-container">  
    
                    {/* <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => sendFurioosMessage("testing","testing")}></i>
                    send sample message to furioos
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => sendSessionConfig()}></i>
                    debugging button for starting session
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => startSpeechRecognition()}></i>
                    startspeech
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => stopSpeechRecognition()}></i>
                    stopspeech
                    </div>
                    <div className="mt-2">
                    <button onClick={stopAndStartPlayer}>stop start player</button>  
                    </div>
                    <div className="mt-2">  
                      <button onClick={StartPlayer}>  
                        {playerLoaded ? 'Start player button' : 'Awaiting player load'}  
                      </button>  
                    </div>  
                    <div className="mt-2">
                    <button onClick={getavail}>get availbility</button>  
                    </div>
                    <text>Is Queuing: {isQueuing ? 'true' : 'false'}   </text>
                    <text>Started App: {AppStarted ? 'true' : 'false   '}</text>
                    <text>  
                      {isQueuing && !AppStarted  
                        ? `Queuing, App should start within ${queueCountdown} seconds.`
                        : !isQueuing && !AppStarted  
                        ? 'No need to queue, App should start soon.'  
                        : 'App is Starting.'}  
                    </text>   */}

      <h1 className="display-4 mb-3"></h1>  
  
      <div className="row main-container">  
        <div  
          style={{  
            position: "relative",  
            width: "100%",  
            paddingTop: (720 / 1280) * 100 + "%", // Maintain 1280x720 aspect ratio  
          }}  
        >  
          <div  
            id="furioos_container"  
            style={{  
              position: "absolute",  
              top: 0,  
              left: 0,  
              width: "100%",  
              height: "100%",  
            }}  
          />  
        </div>  

          <div>  
            {isOpen && (  
              <PopUp  
                content={  
                  <>  
                    <b>Something went wrong...</b>  
                    <p style={{ marginBottom: "20px" }}>  
                      The Virtual Human session was disconnected. Please select  
                      the button below to reset the session.  
                    </p>  
                    {/* <button className="popup-button" onClick={restartFurioosSession}>  
                      Restart  
                    </button>   */}
                    <button className="popup-button" onClick={stopAndStartPlayer}>  
                      Reset  
                    </button>  
                  </>  
                }  
                handleClose={togglePopup}  
              />  
            )}  
          </div>  
      </div>  
      <div  
        className={`white-panel ${whitePanelVisible ? 'visible' : ''}`}  
        style={{  
          position: 'absolute',  
          top: 0,  
          left: 0,  
          width: '100%',  
          height: '100%',  
          backgroundColor: 'white',  
          zIndex: whitePanelVisible ? 1 : -1,  
          display: 'flex',  
          justifyContent: 'center',  
          alignItems: 'center',  
        }}  
      >  
        <button  
          className={`bg-medivrlb-500 text-white font-medium rounded-full px-2 py-1 w-1/5 my-1/5 hover:bg-medivrlb-600 ${playerStarted ? 'hidden' : ''}`}  
          onClick={StartPlayer}  
          disabled={!playerLoaded}  
        >  
          {playerLoaded ? 'Start' : 'Loading Virtual Human Player...'}  
        </button>  

        {isQueuing && !AppStarted && (  
          <div  
            className="bg-gray-200 w-3/4 h-4 rounded-full mt-2 mx-auto"
            style={{ position: 'relative' }}  
          >  
            <div  
              className="bg-medivrlb-500 h-4 rounded-full"  
              style={{  
                position: 'absolute',  
                left: 0,  
                width: `${100 - ((queueCountdown / (TimeToAssignSession)) * 100).toFixed(0)}%`,  
              }}  
            ></div>  
            <text  
              className="mt-1 text-center"  
              style={{  
                display: 'block',  
                width: '100%',  
                position: 'absolute',  
                bottom: '-1.2rem',  
              }}  
            >  
              {queueCountdown > 0  
                ? `${(100 - ((queueCountdown / (TimeToAssignSession)) * 100).toFixed(0))}% - ${queueCountdown} seconds left`  
                : "Loading..."}  
            </text>   
          </div>  
        )}  
        {!isQueuing && !AppStarted && playerStarted &&(
          <text>
            Loading session...
          </text>
        )}
        {!isQueuing && AppStarted && playerStarted &&(
          <text>
            Loading application...
          </text>
        )}

      </div>
      
    </Container>  
  );  
};  
  
export default PlayPage;  
