import { useState, useEffect } from 'react';    
  
function UserInputField({ question, vhc_json_body, autofill, setChangesMade }) {    
  const [value, setValue] = useState(autofill ? question['placeholder'] : '');    
  
  useEffect(() => {    
    function autoResize() {    
      const textarea = document.getElementById(question['name']);    
      if (textarea) {    
        textarea.style.height = 'auto';    
        textarea.style.height = `${textarea.scrollHeight}px`;    
      }    
    }    
    autoResize();    
  }, [value]);    
  
  if (vhc_json_body !== null)    
  {    
    autofill = true;    
  }    
  const name = question['name'];    
  const alt_name = question['alt_name'];    
  const label = question['label'];    
  
  try {    
    if (vhc_json_body.response.data[name] !== undefined)    
    {    
      question['placeholder'] = vhc_json_body.response.data[name]    
    }    
    else if (vhc_json_body.response.data[label] !== undefined){    
      question['placeholder'] = vhc_json_body.response.data[label]    
    }    
    else if (vhc_json_body.response.data[alt_name] !== undefined){    
      question['placeholder'] = vhc_json_body.response.data[alt_name]    
    }    
  }    
  catch(err){    
    console.log(err)    
  }    
  
  // Do not render the component if the question name is 'UID'  
  if (question['name'] === 'UID') {  
    return null;  
  }  
  
  return (    
    <div className='py-2'>    
      <div className='w-[80%] flex items-center'>    
        <div className='block font-medium mb-2 w-32 text-left mr-5 w-[30%]'>{question['label']}</div>    
        <textarea    
          id={question['name']}     
          name={question['name']}     
          placeholder={question['placeholder']}     
          onChange={(e) => {    
            setValue(e.target.value);    
            setChangesMade(true);   
            console.log("Changes made to inputfield") 
          }}    
          defaultValue={autofill ? question['placeholder'] : ''}  
          className='border rounded-lg w-100 px-4 py-2 bg-white text-black border-b-2 resize-none'    
        />    
      </div>    
    </div>    
  );    
}    
  
export default UserInputField;  
