const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "templates"
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`

export const getTemplates = async () => {
  try {
    const fetchurl = url
    console.log(fetchurl)
    const response = await fetch(fetchurl, {
      method: 'GET',
      headers: {
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch templates');
    }

    return response.json();

  } catch (error) {
    console.error(error);
    throw new Error('Failed to get templates');
  }
};

export const getTemplatesById = async (id) => {
  try {
    const fetchurl = url + `/${id}`
    console.log(fetchurl)
    const response = await fetch(fetchurl, {
      method: 'GET',
      headers: {
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch templates');
    }
    return response.json();

  } catch (error) {
    console.error(error);
    throw new Error('Failed to get templates');
  }
};