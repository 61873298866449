const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "convoai_analytics_document/virtual_human";  
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`;  
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`;  
  
export const getConvoAIDocumentsByVirtualHumanId = async (virtualHumanId) => {  
  try {  
    const response = await fetch(`${url}/${virtualHumanId}`, {  
      method: 'GET',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      }  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const documents = await response.json();  
    return documents;  
  } catch (error) {  
    console.error('Error fetching Convo AI documents by Virtual Human ID:', error);  
    throw error;  
  }  
}  

export const getConvoAIDocumentsByVirtualHumanIdAndUserId = async (virtualHumanId, userId) => {  
    try {  
      const response = await fetch(`${url}/${virtualHumanId}/user_id/${userId}`, {  
        method: 'GET',  
        headers: {  
          'Content-Type': 'application/json',  
          'CF-Access-Client-Id': clientId,  
          'CF-Access-Client-Secret': clientSecret,  
        }  
      });  
    
      if (!response.ok) {  
        throw new Error(`Error ${response.status}: ${response.statusText}`);  
      }  
    
      const documents = await response.json();  
      return documents;  
    } catch (error) {  
      console.error('Error fetching Convo AI documents by Virtual Human ID and User ID:', error);  
      throw error;  
    }  
  }

export const deleteConvoAIDocumentsByConvoAIDocumentId = async (convoAIDocumentId) => {  
    try {  
        const response = await fetch(`${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}convoai_analytics_document/${convoAIDocumentId}`, {  
            method: 'DELETE',  
            headers: {  
                'Content-Type': 'application/json',  
                'CF-Access-Client-Id': clientId,  
                'CF-Access-Client-Secret': clientSecret,  
            }  
        });  
        console.log("response", response)
  
        if (!response.ok) {  
            throw new Error(`Error ${response.status}: ${response.statusText}`);  
        }  
  
        if (response.status === 204) {  
            return true;  
        } else {  
            const documents = await response.json();  
            return documents;  
        }  
    } catch (error) {  
        console.error('Error deleting Convo AI documents by Convo AI Document ID:', error);  
        throw error;  
    }  
}

export const ConvoAILogByConvoAIDocumentId = async (convoAIDocumentId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}convoai_logs_document/${convoAIDocumentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'CF-Access-Client-Id': clientId,
                'CF-Access-Client-Secret': clientSecret,
            }
        });

        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const log = await response.json();
        return log;
    } catch (error) {
        console.error('Error fetching Convo AI log by Convo AI Document ID:', error);
        throw error;
    }
}