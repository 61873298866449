import { useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import { useLocation, useNavigate } from "react-router-dom"; 
import { PutVirtualHuman } from "../../api/VirtualHumans";
import minus_cirle from "../../img/minus-circle.png";

const DialogueManagerPage = ({
    selectedCharacter,
    previousVirtualHumanData,
    setPreviousVirtualHumanData,
    simulationReady,
    startSimulation,
    setDMSettings,
    setKnowledgeSettings,
    projectData,
    userDetails
}) => {
    const VirtualHuman = previousVirtualHumanData;
    // const location = useLocation();
    // const VirtualHuman = location.state.VirtualHuman;
    const [phases, setPhases] = useState([]);
    const [jsonString, setJsonString] = useState("");  
    const [jsonObject, setJsonObject] = useState(null);
    const [draftSaved, setDraftSaved] = useState(false);   
    const [changesMade, setChangesMade] = useState(false);  

    const navigate = useNavigate();



    useEffect(() => {
        if (VirtualHuman) {
            try{
            let vhc_json_body = VirtualHuman.vhc_json_body 
            const jsonObject = JSON.parse(vhc_json_body);
            //console.log(JSON.stringify(jsonObject.response.data.phases))
            const newPhases = populatePhases(jsonObject.response.data.phases);
            setPhases(newPhases);
            }
            catch (error) {
                console.warn(error)
            }
        }
    }, []);
    const handleAddPhase = () => {
        const newPhase = {
            context: "",
            mood: "",
            intents: [],
        };
        setPhases([...phases, newPhase]);
        setChangesMade(true);
    };

    const handleRemovePhase = (phaseIndex) => {  
        const updatedPhases = [...phases];  
        updatedPhases.splice(phaseIndex, 1);  
        setPhases(updatedPhases);  
        setChangesMade(true);  
      };  

    const handleAddIntent = (phaseIndex) => {
        const newIntent = {
            intent: "",
            question: "",
            content: "",
            transition: false,
        };
        const updatedPhases = [...phases];
        updatedPhases[phaseIndex].intents.push(newIntent);
        setPhases(updatedPhases);
        setChangesMade(true);
    };

    const handleRemoveIntent = (phaseIndex, intentIndex) => {  
        const updatedPhases = [...phases];  
        updatedPhases[phaseIndex].intents.splice(intentIndex, 1);  
        setPhases(updatedPhases);  
        setChangesMade(true);  
      };  

    const handleIntentCheck = (phaseIndex, intentIndex) => {
        const updatedPhases = [...phases];
        updatedPhases[phaseIndex].intents = updatedPhases[phaseIndex].intents.map(
            (intent, index) => {
                if (index === intentIndex) {
                    return { ...intent, transition: true };
                } else {
                    return { ...intent, transition: false };
                }
            }
        );
        setPhases(updatedPhases);
    };

    const generateJson = () => {  
        const jsonPhases = phases.map((phase) => {  
          const intents = {};  
          phase.intents.forEach((intent) => {  
            intents[intent.intent] = {  
              qn: intent.question,  
              content: intent.content,  
            };  
          });  
          return {  
            context: phase.context,  
            mood: phase.mood,  
            "phase intents": intents,  
            "trigger intent": phase.intents.find((intent) => intent.transition)?.intent || '',  
          };  
        });  
        return jsonPhases;  
      };  


    const populatePhases = (jsonArray) => {    
        if (!Array.isArray(jsonArray)) {    
          return [];    
        }    
          
        const newPhases = jsonArray.map((phase) => {    
          const intents = [];    
          Object.keys(phase["phase intents"]).forEach((intentKey) => {    
            intents.push({    
              intent: intentKey,    
              question: phase["phase intents"][intentKey].qn,    
              content: phase["phase intents"][intentKey].content,    
              transition: null,    
            });    
          });    
          const newPhase = {    
            context: phase.context,    
            mood: phase.mood,    
            intents,    
          };    
          const triggerIntent = phase["trigger intent"];    
          if (triggerIntent) {    
            const transitionIntent = newPhase.intents.find(    
              (intent) => intent.intent === triggerIntent    
            );    
            if (transitionIntent) {    
              transitionIntent.transition = true;    
            }    
          }    
          return newPhase;    
        });    
          
        return newPhases;    
      };  


    const inputIntentAnalytics = () => {
        console.log("virtualHuman to pass over to intent analytics input:", VirtualHuman)
        navigate('/intent-analytics', {  
            state: {  
                virtualHuman: VirtualHuman,
                projectData: projectData,
                userDetails: userDetails
            },  
          }); 
    }
    

    const saveAsDraft = () => {
        const newPhases = generateJson();
        //console.log(newPhases)
        let newVirtualHuman = {...VirtualHuman}; // create a copy of the original VirtualHuman object
        //console.log(newVirtualHuman.vhc_json_body)
        let OG_JSON = JSON.parse(newVirtualHuman.vhc_json_body) // the josn message is a string! need to parse it first!
        //console.log(OG_JSON)
        if (OG_JSON === null) {  
            OG_JSON = {}; // create a new object  
        }  
        if (OG_JSON.response === undefined) {  
            OG_JSON.response = {}; // create a new object  
        }  
        if (OG_JSON.response.data === undefined) {  
            OG_JSON.response.data = {}; // create a new object  
        }  
        if (OG_JSON.response.data.phases === undefined) {  
            OG_JSON.response.data.phases = []; // create a new phases array  
        }  

        
        OG_JSON.response.data.phases = newPhases;
        //console.log(OG_JSON)
        newVirtualHuman.vhc_json_body = JSON.stringify(OG_JSON);
        newVirtualHuman.status = "Draft"
        console.log("Check if its virtualhuman_name or virtualhuman_name please: ",newVirtualHuman)
        PutVirtualHuman(newVirtualHuman._id, newVirtualHuman.virtualhuman_name, newVirtualHuman.virtualhuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
        
        setDraftSaved(true);  
        setChangesMade(false);
        setTimeout(() => {  
        setDraftSaved(false);
        setPreviousVirtualHumanData(newVirtualHuman);
        }, 1000);  // Change the button to "Saved" for 3 seconds

    };

    const createVH = () => {
        saveAsDraft();
        setDMSettings(true)
        console.log("create VH")
    }

    const buttonClass = `font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-blue-900 ${  
        changesMade ? "bg-orange-500" : "bg-gray-700"  
      } ${draftSaved ? "bg-green-500" : ""}`;  

    return (
        <div id="Dialogue Manager" className="flex-1 grid grid-cols-6 text-white">

            <SideBar />

            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide"> 
                <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  
                            Create Your Virtual Human  
                            <div className="font-light text-3xl ml-4">  
                                - Conversation
                            </div>  
                </div> 

                <div className="rounded-full" style={{ display: 'flex', flexDirection: 'column' , height: "300px", width: "90%"}}>  
                
                    {phases.map((phase, phaseIndex) => (
                        
                        <div key={phaseIndex} className="bg-medivrlb-800 p-5 mb-5 rounded-md">
                            
                            <div style={{ marginBottom: "2rem" }} className="flex">

                                <div className="w-3/4">
                                    <div className='w-[80%] flex items-center'>  
                                        <label className="mr-5">Context:</label>  
                                        <textarea className="rounded-lg w-100 p-2 bg-white text-black" value={phase.context} 
                                        onChange={(e) => {            
                                            const updatedPhases = [...phases];
                                            updatedPhases[phaseIndex].context = e.target.value;
                                            setPhases(updatedPhases);
                                            setChangesMade(true);
                                        }}
                                        style={{ color: 'black' , minHeight: '50px' }} />  
                                    </div>   
                                </div>

                                <div>
                                
                                    <div className="mr-5"> 
                                        <div className='w-[100%] flex items-center'> 
                                            <label className="mr-5">Mood:</label>  
                                            <textarea className="rounded-lg w-100 p-2 bg-white text-black" value={phase.mood} 
                                            onChange={(e) => {            
                                                const updatedPhases = [...phases];
                                                updatedPhases[phaseIndex].mood = e.target.value;
                                                setPhases(updatedPhases);
                                                setChangesMade(true);
                                            }}
                                            style={{ color: 'black'}} />  
                                        </div> 
                                    </div>
                                </div>

                                <button   
                                      
                                    onClick={() => handleRemovePhase(phaseIndex)}>
                                    
                                    <img src={minus_cirle} alt="Delete" style={{ width: "40px", height: "40px" }} />  

                                </button> 

                            </div>

                            <div>


                                {phase.intents.map((intent, intentIndex) => (
                                    <div key={intentIndex} className="bg-medivrlb-700 p-4 mb-4 rounded-md">

                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>  
                                            <button     
                                                
                                                onClick={() => handleRemoveIntent(phaseIndex, intentIndex)}>    
                                                 
                                                <img src={minus_cirle} alt="Delete" style={{ width: "40px", height: "40px" }} />
                                            </button>  
                                        </div>  

                                        <div>
                                            <label className="mr-5">Transition:</label>
                                            <input
                                                type="checkbox"
                                                checked={intent.transition}
                                                onChange={() => {
                                                    handleIntentCheck(phaseIndex, intentIndex)
                                                    setChangesMade(true);
                                                }}
                                                style={{ color: 'black' , marginBottom: "2rem" }}

                                            />
                                        </div>
                                        <div class="flex flex-col gap-10 justify-end">  
                                            <div className="w-[90%] flex items-center">  
                                                <label className="mb-2 w-32 text-left mr-5 w-[7%]">Intent:</label>  
                                                <textarea className="w-full h-10 resize-none overflow-y-auto rounded-md p-2" value={intent.intent} onChange={(e) => {            
                                                    const updatedPhases = [...phases];  
                                                    updatedPhases[phaseIndex].intents[intentIndex].intent = e.target.value;  
                                                    setPhases(updatedPhases);  
                                                    setChangesMade(true);
                                                }} style={{ color: 'black' }} />  
                                            </div>  
                                            
                                            <div className="w-[90%] flex items-center">  
                                                <label className="mb-2 w-32 text-left mr-5 w-[7%]">Question:</label>  
                                                <textarea className="w-full h-10 resize-none overflow-y-auto rounded-md p-2" value={intent.question} onChange={(e) => {            
                                                    const updatedPhases = [...phases];  
                                                    updatedPhases[phaseIndex].intents[intentIndex].question = e.target.value;  
                                                    setPhases(updatedPhases);
                                                    setChangesMade(true);
                                                }} style={{ color: 'black' }} />  
                                            </div>  
                                            
                                            <div className="w-[90%] flex items-center">  
                                                <label className="mb-2 w-32 text-left mr-5 w-[7%]">Content:</label>  
                                                <textarea className="w-full h-20 overflow-y-auto rounded-md p-2" value={intent.content} onChange={(e) => {            
                                                    const updatedPhases = [...phases];  
                                                    updatedPhases[phaseIndex].intents[intentIndex].content = e.target.value;  
                                                    setPhases(updatedPhases);
                                                    setChangesMade(true);
                                                }} style={{ color: 'black' , minHeight: '50px'  , marginBottom: "1rem" }} />  
                                            </div> 

                                        </div>  

                                    </div>
                                ))}
                                <button 
                                    className="bg-gray-700 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-blue-900"
                                    onClick={() => handleAddIntent(phaseIndex)}>
                                    Add Intent
                                </button>
                            </div>
                        </div>
                    ))}
                            
                    <button 
                        className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 hover:bg-medivrlb-600" style={{ maxHeight: "30px"}}
                        onClick={handleAddPhase}>Add Phase
                    </button>
                </div>  


            </div>
            <div className="flex justify-end"> 
                <div className="flex flex-col">  
                <img className='bg-gray-900/80 rounded-lg w-48' src={selectedCharacter && selectedCharacter['src']} alt="" />

                    
                    {simulationReady.isReady ? (
                            <div className='text-center p-4 w-48'>
                                <div>Virtual Human created successfully!</div>
                                <button className='bg-gray-900 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-blue-900' onClick={startSimulation}>Start</button>
                            </div>
                        ) : (
                            simulationReady.isLoading ? (
                                <div class="text-center p-4">
                                    <div role="status">
                                        <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600'  style={{ maxHeight: "30px"}} onClick={() => {setKnowledgeSettings(null); setDMSettings(null);}}>Back</button>
                                    <input type='submit'  value='Create!'  style={{ maxHeight: "30px"}} onClick={() => createVH()} className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600' />
                                    {simulationReady.isError && <div className='text-xs w-48'>There was a problem creating your virtual human, please try again later.</div>}
                                </>
                            )
                        )}
                    <button
                        className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 my-4 hover:bg-medivrlb-600"style={{ maxHeight: "30px" }}
                        onClick={inputIntentAnalytics}>  
                        Input Intent Analytics
                    </button>
                    <button
                        className={buttonClass} style={{ maxHeight: "30px" }}
                        onClick={saveAsDraft}>  
                        {draftSaved ? 'Draft Saved' : 'Save as Draft'}  
                    </button>


                </div> 
            </div>



            {/* <button onClick={handleSave}>Save as Json FOR DEBUGGING ONLY!</button>
            <div>  
                <label htmlFor="json-input">Enter JSON string:</label>  
                <textarea  
                    id="json-input"  
                    value={jsonString}  
                    onChange={handleJsonInputChange}
                    style={{ color: 'black' }}  
                />  
                <button onClick={handleLoad} disabled={!jsonObject}>  
                    Load  
                </button>  
            </div>   */}
        </div>
        
    );
};

export default DialogueManagerPage;