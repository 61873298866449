const SideBar = () => (
    <div className='pr-4 border-r-2'>
        <div className="py-2 border-b-2">
            <div className='font-medium'>Virtual Human Information</div>
            <div className='text-sm'>
                <div className='pl-2 py-2 cursor-pointer hover:bg-gray-900/50 rounded'>Name & Description</div>
            </div>
        </div>
        <div className="py-2 border-b-2">
            <div className='font-medium'>Virtual Human</div>
            <div className=' text-sm '>
                <div className='pl-2 py-2 cursor-pointer hover:bg-gray-900/50 rounded'>Appearance</div>
                <div className='pl-2 py-2 cursor-pointer hover:bg-gray-900/50 rounded'>Information</div>
                <div className='pl-2 py-2 cursor-pointer hover:bg-gray-900/50 rounded'>Conversation</div>
            </div>
        </div>
    </div>
)

export default SideBar