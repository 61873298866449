const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "projects"
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`



// export const postProject = async ( data) => {  
//   const formData = new FormData();  
  
//   for (const key in data) {  
//     console.log(key,data)
//     formData.append(key, data[key]);  
//   }  
  
//   try {  
//     const response = await fetch(url, {  
//       method: 'POST',  
//       headers: {  
//         'Content-Type': 'application/json',
//         'CF-Access-Client-Id': clientId,
//         'CF-Access-Client-Secret': clientSecret,
//       },
//       body: formData
//     });  
  
//     if (!response.ok) {  
//       throw new Error(`Error ${response.status}: ${response.statusText}`);  
//     }  
  
//     const result = await response.json();  
//     return result;  
//   } catch (error) {  
//     console.error('Error posting project:', error);  
//     throw error;  
//   }  
// }  
export const postProject = async (data) => {  
  // const formData = new FormData();  
  
  // for (const key in data) {  
  //   console.log(key,data)
  //   formData.append(key, data[key]);  
  // }  
  
  try {  
    const response = await fetch(url, {  
      method: 'POST',  
      headers: {  
        'Content-Type': 'application/json',
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },
      body: data
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error posting project:', error);  
    throw error;  
  }  
}  


export const getProjectsForOrganization = async (organisation) => {  
  try {  
    const response = await fetch(`${url}/org/${organisation}`, {  
      method: 'GET',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      }  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const projects = await response.json();  
    return projects;  
  } catch (error) {  
    console.error('Error fetching projects for organization:', error);  
    throw error;  
  }  
}

export const getProjectById = async (id) => {  
  try {  
    const response = await fetch(`${url}/${id}`, {  
      method: "GET",  
      headers: {  
        "Content-Type": "application/json",  
        "CF-Access-Client-Id": clientId,  
        "CF-Access-Client-Secret": clientSecret,  
      },  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const project = await response.json();  
    return project;  
  } catch (error) {  
    console.error("Error fetching project by ID:", error);  
    throw error;  
  }  
};  


export const deleteProject = async (id) => {
    console.log(id)
    const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "projects" + "/" + id;
    console.log(url)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'CF-Access-Client-Id': clientId,
                'CF-Access-Client-Secret': clientSecret,
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to delete project with ID ${id}: ${response.statusText}`);
        }
        return response;
    } catch (error) {
        throw new Error(`Failed to delete project with ID ${id}: ${error.message}`);
    }
};

export const updateProjectMaximumUsageTime = async (id, editedBy, newMaximumUsageTimePerPeriod) => {  
  try {  
    const response = await fetch(`${url}/${id}/update_maximum_usage_time`, {  
      method: 'PUT',  
      headers: {  
        'Content-Type': 'application/json',
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },  
      body: JSON.stringify({  
        edited_by: editedBy,  
        new_maximum_usage_time_per_period: newMaximumUsageTimePerPeriod  
      })  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error updating project maximum usage time:', error);  
    throw error;  
  }  
}  

export const addUsersToProject = async (id, newUserIds, editedBy) => {  
  try {  
    const response = await fetch(`${url}/${id}/add_users`, {  
      method: 'POST',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },  
      body: JSON.stringify({  
        new_user_ids: newUserIds,  
        edited_by: editedBy  
      })  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error adding users to project:', error);  
    throw error;  
  }  
}

export const removeUsersFromProject = async (id, userIdsToRemove, editedBy) => {  
  try {  
    const response = await fetch(`${url}/${id}/remove_users`, {  
      method: 'DELETE',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },  
      body: JSON.stringify({  
        user_ids_to_remove: userIdsToRemove,  
        edited_by: editedBy  
      })  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error removing users from project:', error);  
    throw error;  
  }  
}

export const updateProjectNameAndDescription = async (id, newName, newDescription, editedBy) => {  
  try {  
    const response = await fetch(`${url}/${id}/update_name_description`, {  
      method: 'PUT',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      },  
      body: JSON.stringify({  
        new_project_name: newName,  
        new_project_description: newDescription,  
        edited_by: editedBy,  
      }),  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error updating project name and description:', error);  
    throw error;  
  }  
};

export const addVirtualHumansToProject = async (id, newVirtualHumanIds, editedBy) => {  
  try {   
    const response = await fetch(`${url}/${id}/add_virtual_humans`, {  
      method: 'POST',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      },  
      body: JSON.stringify({  
        new_virtual_human_ids: newVirtualHumanIds,  
        edited_by: editedBy,  
      }),  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error adding virtual humans to project:', error);  
    throw error;  
  }  
};  

// const projectId = '60f72f4b7c4d4ad8db7d0ed0';  
// const newVirtualHumanIds = ['vh1', 'vh2', 'vh3'];  
// const editedBy = 'user123';  
  
// addVirtualHumansToProject(projectId, newVirtualHumanIds, editedBy)  
//   .then((result) => console.log('Virtual humans added to project successfully:', result))  
//   .catch((error) => console.error('Error adding virtual humans to project:', error));  

export const removeVirtualHumansFromProject = async (id, virtualHumanIdsToRemove, editedBy) => {  
  try {  
    const response = await fetch(`${url}/${id}/remove_virtual_humans`, {  
      method: 'DELETE',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      },  
      body: JSON.stringify({  
        virtual_human_ids_to_remove: virtualHumanIdsToRemove,  
        edited_by: editedBy,  
      }),  
    });  
  
    if (!response.ok) {  
      throw new Error(`Error ${response.status}: ${response.statusText}`);  
    }  
  
    const result = await response.json();  
    return result;  
  } catch (error) {  
    console.error('Error removing virtual humans from project:', error);  
    throw error;  
  }  
};  

// const projectId = '60f72f4b7c4d4ad8db7d0ed0';  
// const virtualHumanIdsToRemove = ['vh1', 'vh2', 'vh3'];  
// const editedBy = 'user123';  
  
// removeVirtualHumansFromProject(projectId, virtualHumanIdsToRemove, editedBy)  
//   .then((result) => console.log('Virtual humans removed from project successfully:', result))  
//   .catch((error) => console.error('Error removing virtual humans from project:', error));  
