import React from "react";  
import { useNavigate } from "react-router-dom";  
import minus_circle from '../img/minus-circle.png';  
  
const ProjectCard = ({ project, userDetails, onDelete }) => {  
    const navigate = useNavigate();  
  
    const formatDate = (dateString) => {  
        const date = new Date(dateString);  
        const day = String(date.getDate()).padStart(2, '0');  
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed  
        const year = date.getFullYear();  
  
        return `${day}/${month}/${year}`;  
    };  
  
    return (  
        <div className="bg-black70 rounded-lg p-4 my-3 mx-4 relative" style={{  
            display: "flex",  
            flexDirection: "row",  
            alignItems: "center",  
            width: "500px",  
            height: "350px",  
            borderRadius: "10px",  
            padding: "16px",  
        }}>  {(userDetails.role === "operator"|| userDetails.role === "developer") && ( 
                <button  
                    style={{ alignSelf: "flex-start" }}  
                    onClick={() => {  
                        onDelete(project._id);  
                    }}  
                >  
                    <img src={minus_circle} alt="Delete" style={{ width: "20px", height: "20px" }} />  
                </button>  
            )}  
  
            <div className="bw-full flex-col">  
                <div className="flex justify-between items-start w-full">  
                    <div className="text-white text-l font-medium mr-4">  
                        {project.project_name}  
                    </div>  
                    <div className="flex flex-col">  
                        {(userDetails.role === "operator"|| userDetails.role === "developer") && (  
                            <button  
                                className="common-btn bg-amber-500 text-white font-medium rounded-full px-3 py-2 hover:bg-amber-600 mb-2"  
                                onClick={() => {  
                                    navigate("/createProject", { state: { project, userDetails } });  
                                }}  
                            >  
                                Edit  
                            </button>  
                        )}  
                        {(userDetails.role === "user" || userDetails.role === "operator"  || userDetails.role === "developer" ) && (  
                            <button  
                                className="common-btn bg-blue-500 text-white font-medium rounded-full px-3 py-2 hover:bg-blue-600mb-2"  
                                onClick={() => {  
                                navigate("/virtualhumans", {  
                                    state: { projectData: project, userDetails: userDetails },  
                                    });  
                                }} 
                            >  
                                Virtual Humans  
                            </button>  
                        )}  
                    </div>  
                </div>  
                <div className="text-sm text-white">Date Created:</div>  
                <div className="text-white text-l font-medium" style={{ marginBottom: "0.5rem" }}>{formatDate(project.projects_metadata.date_created)}</div>  
                <div className="text-sm text-white">Number of Accounts:</div>  
                <div className="text-white text-l font-medium" style={{ marginBottom: "0.5rem" }}>{Object.keys(project.account_access).length}</div>  
            </div>  
        </div>  
    );  
};  
  
export default ProjectCard;  