
const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "user-details"
//const url = "http://127.0.0.1:6001/" + "user-details"
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`
const jwt_secret = `${process.env.REACT_APP_JWT_SECRET}`


export const getUserDetails = async (localAccountId) => {
    //const encodedId = jwt.sign({ sub: localAccountId }, jwt_secret, { algorithm: 'HS256' });
    const response = await fetch(`${url}/${localAccountId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'CF-Access-Client-Id': clientId,
            'CF-Access-Client-Secret': clientSecret,
        }
    });
    if (!response.ok) {
        throw new Error(`Failed to get user details: ${response.status}`);
    }
    const data = await response.json();
    return data;
};

export const getUserName = async (_id) => {  
  const response = await fetch(`${url}/name/${_id}`, {  
      method: 'GET',  
      headers: {  
          'Content-Type': 'application/json',  
          'CF-Access-Client-Id': clientId,  
          'CF-Access-Client-Secret': clientSecret,  
      }  
  });  
  if (!response.ok) {  
      throw new Error(`Failed to get user name: ${response.status}`);  
  }  
  const data = await response.text();  
  return data;  
};  


export const postUserDetails = async (localAccountId, role, org, sub_org) => {
  const formData = new FormData();
  formData.append('role', role);
  formData.append('org', org);
  formData.append('sub_org', sub_org);

  console.log(`${url}/${localAccountId}`)
  const response = await fetch(`${url}/${localAccountId}`, {
    method: 'POST',
    headers: {
      'CF-Access-Client-Id': clientId,
      'CF-Access-Client-Secret': clientSecret,
    },
    body: formData,
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const data = await response.json();
  return data;
};
export const putUserDetails = async (localAccountId, role, org, sub_org) => {
  const formData = new FormData();
  formData.append('role', role);
  formData.append('org', org);
  formData.append('sub_org', sub_org);
  const response = await fetch(`${url}/${localAccountId}`, {
    method: 'PUT',
    headers: {
      'CF-Access-Client-Id': clientId,
      'CF-Access-Client-Secret': clientSecret,
    },
    body: formData,
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const data = await response.json();
  return data;
};


export const deleteUserDetails = async (localAccountId) => {
  const response = await fetch(`${url}/${localAccountId}`, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'CF-Access-Client-Id': clientId,
          'CF-Access-Client-Secret': clientSecret,
      }
  });

  if (!response.ok) {
      throw new Error(response.statusText);
  }

  const data = await response.json();
  return data;
};

export const startTimer = async (localAccountId) => {
  const response = await fetch(`${url}/${localAccountId}/time/start`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'CF-Access-Client-Id': clientId,
          'CF-Access-Client-Secret': clientSecret,
      }
  });

  if (!response.ok) {
      throw new Error(response.statusText);
  }

  const data = await response.json();
  console.log(data)
  return data;
};

export const stopTimer = async (localAccountId) => {
  const response = await fetch(`${url}/${localAccountId}/time/stop`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'CF-Access-Client-Id': clientId,
          'CF-Access-Client-Secret': clientSecret,
      }
  });

  if (!response.ok) {
      throw new Error(response.statusText);
  }

  const data = await response.json();
  console.log(data)
  return data;
};
