import React, { useEffect, useState } from 'react';  
import { useNavigate, useLocation } from "react-router-dom";  
import { getConvoAIDocumentsByVirtualHumanId, getConvoAIDocumentsByVirtualHumanIdAndUserId, deleteConvoAIDocumentsByConvoAIDocumentId } from '../../api/Analytics';  
import { getUserName } from '../../api/UserDetails';  
import SideBar from '../../components/SideBar';  
import MainContainer from '../../components/MainContainer';  
  
const ConvoAnalyticsLandingPage = () => {  
    const navigate = useNavigate();  
    const location = useLocation();  
    const userDetails = location.state?.userDetails;  
    const projectData = location.state?.projectData;  
    const virtualHuman = location.state?.virtualHuman;  
  
    const [convoAIDocuments, setConvoAIDocuments] = useState([]);  
    const [userNames, setUserNames] = useState({});  
  
    const fetchConvoAIDocuments = async () => {  
        try {  
            const documents = await getConvoAIDocumentsByVirtualHumanId(virtualHuman._id);  
            setConvoAIDocuments(documents);  
        } catch (error) {  
            console.log("error: ", error);  
        }  
    };  
  
    const fetchUserName = async (user_id) => {  
        if (!userNames[user_id]) {  
            try {  
                let userName = await getUserName(user_id);  
                userName = userName.replace(/"/g, ''); // Remove double quotes  
                setUserNames((prevUserNames) => ({ ...prevUserNames, [user_id]: userName }));  
            } catch (error) {  
                console.log("error: ", error);  
            }  
        }  
    };  

    const handleDeleteButtonClick = async (convoaiDocumentId) => {  
        try {  
            await deleteConvoAIDocumentsByConvoAIDocumentId(convoaiDocumentId);  
            const updatedConvoAIDocuments = convoAIDocuments.filter(doc => doc._id !== convoaiDocumentId);  
            setConvoAIDocuments(updatedConvoAIDocuments);  
        } catch (error) {  
            console.log("error: ", error);  
        }  
    };
  
    const handleButtonClick = (convoaiDocument) => {  
        navigate("/convoai-analytics", {  
            state: { convoaiDocument: convoAIDocuments.find(doc => doc._id === convoaiDocument) , userDetails},  
        });  
    };  
  
    useEffect(() => {  
        fetchConvoAIDocuments();  
    }, []);  
  
    useEffect(() => {  
        convoAIDocuments.forEach((doc) => fetchUserName(doc.analytics_metadata.user));  
    }, [convoAIDocuments]);  
  
    return (  
        <MainContainer>  
            <div className="flex-1 grid grid-cols-6 text-white text-left">  
                <SideBar />  
                <div className="col-span-5 p-4">  
                    <h2 className="text-xl font-semibold mb-4">Convo AI Analytics: {virtualHuman.virtualhuman_name}</h2>  
                    <div className="overflow-auto max-h-[700px]">  
                        <table className="min-w-full bg-white text-black divide-y divide-gray-200">  
                            <thead className="bg-gray-300">  
                                <tr>  
                                    <th className="px-4 py-2">Username</th>  
                                    <th className="px-4 py-2">Time</th>  
                                    <th className="px-4 py-2">Status</th>  
                                    <th className="px-4 py-2">Action</th>  
                                    {(userDetails.role === "operator"|| userDetails.role === "developer") && ( 
                                    <th className="px-4 py-2">Delete</th>  
                                    )}
                                </tr>  
                            </thead>  
                            <tbody className="divide-y divide-gray-200">  
                                {convoAIDocuments.map((doc) => (  
                                    <tr key={doc._id}>  
                                        <td className="px-4 py-2">{userNames[doc.analytics_metadata.user]}</td>  
                                        <td className="px-4 py-2">{doc.analytics_metadata.timestamp}</td>  
                                        <td className="px-4 py-2">  
                                            <span  
                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${  
                                                    doc.status === 'pending analytics'  
                                                        ? 'bg-yellow-100 text-yellow-800'  
                                                        : 'bg-green-100 text-green-800'  
                                                }`}  
                                            >  
                                                {doc.status}  
                                            </span>  
                                        </td>  
                                        <td className="px-4 py-2">  
                                            <button  
                                                className="bg-blue-500 font-small rounded-full px-2 py-1 h-12 w-47 my-4 hover:bg-blue-600 text-white my-3 mx-4"  
                                                onClick={() => handleButtonClick(doc._id)}  
                                            >  
                                                View  
                                            </button>  
                                        </td>  
                                        {(userDetails.role === "operator"|| userDetails.role === "developer") && ( 
                                        <td className="px-4 py-2">  
                                            <button  
                                                className="bg-red-500 font-small rounded-full px-2 py-1 h-12 w-47 my-4 hover:bg-red-600 text-white my-3 mx-4"  
                                                onClick={() => handleDeleteButtonClick(doc._id)}  
                                            >  
                                                Delete  
                                            </button>  
                                        </td>  
                                        )}
                                    </tr>  
                                ))}  
                            </tbody>  
                        </table> 
                    </div>  
                </div>  
            </div>  
        </MainContainer>  
    );  
}  
  
export default ConvoAnalyticsLandingPage;  