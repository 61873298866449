import axios from 'axios';
import Cookie from 'universal-cookie';

export async function getTokenOrRefresh(forceRefresh = false) {  
    if (!forceRefresh) {  
      const cookie = new Cookie();  
      const speechToken = cookie.get('speech-token');  
    
      if (speechToken !== undefined) {  
        console.log('Token fetched from cookie: ' + speechToken);  
        const idx = speechToken.indexOf(':');  
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };  
      }  
    }  
    
    try {  
      const res = await axios.get('https://interactai-typescript.azurewebsites.net/api/speechtoken-get');  
      const token = res.data.token;  
      const region = res.data.region;  
    
      if (!forceRefresh) {  
        const cookie = new Cookie();  
        cookie.set('speech-token', region + ':' + token, { maxAge: 540, path: '/' });  
      }  
    
      console.log('Token fetched from back-end: ' + token);  
      return { authToken: token, region: region };  
    } catch (err) {  
      console.log("Error! First backend has failed for getting tokens. Utilising backup.");  
      try {  
        const res = await axios.get('https://speechtoken.medivr.workers.dev');  
        const token = res.data.token;  
        const region = res.data.region;  
    
        if (!forceRefresh) {  
          const cookie = new Cookie();  
          cookie.set('speech-token', region + ':' + token, { maxAge: 540, path: '/' });  
        }  
    
        console.log('Token fetched from back-end: ' + token);  
        return { authToken: token, region: region };  
      } catch (err) {  
        console.log(err.response.data);  
        return { authToken: null, error: err.response.data };  
      }  
    }  
  }  
  