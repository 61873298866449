import Caucasian_Adult_F_01c from '../img/characters/Caucasian_Adult_F_01c.png'
import Caucasian_Adult_F_01f from '../img/characters/Caucasian_Adult_F_01f.png'
import Caucasian_Adult_M_01c from '../img/characters/Caucasian_Adult_M_01c.png'
import Caucasian_Adult_M_01f from '../img/characters/Caucasian_Adult_M_01f.png'
import Chinese_Adult_F_01f from '../img/characters/Chinese_Adult_F_01f.png'
import Chinese_Adult_F_01c from '../img/characters/Chinese_Adult_F_01c.png'
import Chinese_Adult_M_01f from '../img/characters/Chinese_Adult_M_01f.png'
import Chinese_Adult_M_01c from '../img/characters/Chinese_Adult_M_01c.png'
import Chinese_Elderly_F_01c from '../img/characters/Chinese_Elderly_F_01c.png'
import Chinese_MiddleAge_M_01c from '../img/characters/Chinese_MiddleAge_M_01c.png'
import Indian_Adult_F_01f from '../img/characters/Indian_Adult_F_01f.png'
import Indian_Adult_F_01c from '../img/characters/Indian_Adult_F_01c.png'
import Indian_Adult_M_01f from '../img/characters/Indian_Adult_M_01f.png'
import Indian_Adult_M_01c from '../img/characters/Indian_Adult_M_01c.png'
import Malay_Adult_F_01f from '../img/characters/Malay_Adult_F_01f.png'
import Malay_Adult_F_01c from '../img/characters/Malay_Adult_F_01c.png'
import Malay_Adult_M_01f from '../img/characters/Malay_Adult_M_01f.png'
import Malay_Adult_M_01c from '../img/characters/Malay_Adult_M_01c.png'
import Malay_Elderly_M_01c from '../img/characters/Malay_Elderly_M_01c.png'
import Malay_Elderly_F_01c from '../img/characters/Malay_Elderly_F_01c.png'

export const getCharacterByName = (name) => {
    return characters.find(character => character.name === name);
    };

export const characters = [
    {
    name: 'Caucasian_Adult_F_01c',
    src: Caucasian_Adult_F_01c,
    gender: 'female'
    }, {
    name: 'Caucasian_Adult_F_01f',
    src: Caucasian_Adult_F_01f,
    gender: 'female'
    }, {
    name: 'Caucasian_Adult_M_01c',
    src: Caucasian_Adult_M_01c,
    gender: 'male'
    }, {
    name: 'Caucasian_Adult_M_01f',
    src: Caucasian_Adult_M_01f,
    gender: 'male'
    }, {
    name: 'Chinese_Adult_F_01f',
    src: Chinese_Adult_F_01f,
    gender: 'female'
    },{
    name: 'Chinese_Adult_F_01c',
    src: Chinese_Adult_F_01c,
    gender: 'female'
    },{
    name: 'Chinese_Adult_M_01f',
    src: Chinese_Adult_M_01f,
    gender: 'male'
    },{
    name: 'Chinese_Adult_M_01c',
    src: Chinese_Adult_M_01c,
    gender: 'male'
    },{
    name: 'Chinese_Elderly_F_01c',
    src: Chinese_Elderly_F_01c,
    gender: 'female'
    },{
    name: 'Chinese_MiddleAge_M_01c',
    src: Chinese_MiddleAge_M_01c,
    gender: 'male'
    },{
    name: 'Indian_Adult_F_01f',
    src: Indian_Adult_F_01f,
    gender: 'female'
    },{
    name: 'Indian_Adult_F_01c',
    src: Indian_Adult_F_01c,
    gender: 'female'
    },{
    name: 'Indian_Adult_M_01f',
    src: Indian_Adult_M_01f,
    gender: 'male'
    },{
    name: 'Indian_Adult_M_01c',
    src: Indian_Adult_M_01c,
    gender: 'male'
    },{
    name: 'Malay_Adult_F_01f',
    src: Malay_Adult_F_01f,
    gender: 'female'
    },{
    name: 'Malay_Adult_F_01c',
    src: Malay_Adult_F_01c,
    gender: 'female'
    },{
    name: 'Malay_Adult_M_01f',
    src: Malay_Adult_M_01f,
    gender: 'male'
    },{
    name: 'Malay_Adult_M_01c',
    src: Malay_Adult_M_01c,
    gender: 'male'
    },
    {
    name: 'Malay_Elderly_M_01c',
    src: Malay_Elderly_M_01c,
    gender: 'male'
    },
    {
    name: 'Malay_Elderly_F_01c',
    src: Malay_Elderly_F_01c,
    gender: 'female'
    },

]