const imageUploadURL = 'https://interactai-creation-upload-image.medivr.workers.dev'

export const uploadImage = async (image) => {
    const data = new FormData()
    data.append('file', image, image.name)
    return fetch(imageUploadURL, {
        method: 'POST',
        body: data,
    })
        .then(res => res.json())
        .catch(err => console.log(err))
}