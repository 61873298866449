import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import MainContainer from "../../components/MainContainer";
import ProjectInformationPage from "./ProjectInformationPage";
import AccessPage from "./AccessPage";


const OnboardingStates = {
  INPUT_PROJECT_INFO: 0,
  SELECT_ACCOUNT_ACCESS: 1,

};

const CreateProjectPage = () => {

    const location = useLocation();
    const Project = location.state.project;
    const UserDetails = location.state.userDetails;
    console.log("userDetails: " , UserDetails)
    console.log(Project)

    const [onboardingState, setOnboardingState] = useState(
    OnboardingStates.INPUT_PROJECT_INFO
    );
    const [previousProjectData, setPreviousProjectData] = useState(Project);
    const [ProjectInfo, setProjectInfo] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState(null);

    const [projectSceneName, setProjectSceneName] = useState(Project.project_name);
    const [projectSceneDescription, setProjectSceneDescription] = useState(Project.project_description);


  useEffect(() => {
    console.log(ProjectInfo)
    if (ProjectInfo === null) {
      setOnboardingState(0);
    }
    else{
        if (selectedUsers === null) {
            setOnboardingState(1);
        }
    }
}, [ProjectInfo, selectedUsers]);


useEffect(() => {
    setPreviousProjectData((prevData) => {
      return { ...prevData, project_name: projectSceneName };
    });
  }, [projectSceneName]);

useEffect(() => {
    setPreviousProjectData((prevData) => {
      return { ...prevData, project_description: projectSceneDescription };
    });
  }, [projectSceneDescription]);





  return (
    <MainContainer>
      {onboardingState === OnboardingStates.INPUT_PROJECT_INFO && (
        <ProjectInformationPage
        userDetails={UserDetails}
        setProjectInfo={setProjectInfo}
        previousProjectData={previousProjectData}
        setPreviousProjectData={setPreviousProjectData}

        projectSceneName={projectSceneName}
        setProjectSceneName={setProjectSceneName}

        projectSceneDescription={projectSceneDescription}
        setProjectSceneDescription={setProjectSceneDescription}

        />
      )}
      {onboardingState === OnboardingStates.SELECT_ACCOUNT_ACCESS && (
        <AccessPage
        myUserDetail={UserDetails}
        setSelectedUsers={setSelectedUsers}
        previousProjectData={previousProjectData}
        setPreviousProjectData={setPreviousProjectData}
        />
      )}
    </MainContainer>
  );
};

export default CreateProjectPage;
