import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import SideBar from "../../components/SideBar"
import UserInputField from "../../components/UserInputField"
import { formQuestions } from "../../data/CreationFormData"
import { PutVirtualHuman } from "../../api/VirtualHumans";

const KnowledgeFormPage = ({
    selectedCharacter,
    setVoiceSettings,
    setKnowledgeSettings,
    previousVirtualHumanData,
    setPreviousVirtualHumanData,
    confirmKnowledgeSettings,
    simulationReady,
    startSimulation
}) => {

    const [autofill, setAutofill] = useState(false)
    const [searchParams] = useSearchParams()

    const [draftSaved, setDraftSaved] = useState(false);   
    const [changesMade, setChangesMade] = useState(false);  

    const [buttonClicked, setButtonClicked] = useState(null) 

    //console.log(previousVirtualHumanData)

    useEffect(() => {
        if (searchParams.get('autofill') === '1') {
            setAutofill(true)
        }
    }, [searchParams])

    useEffect(() => {
            console.log("Changes made : ", changesMade)
    }, [changesMade])

    const submitKnowledgeForm = e => {
        e.preventDefault()
        console.log(e.target.name)

        let OG_json_body = JSON.parse(previousVirtualHumanData.vhc_json_body);
        let tempUid = null;

        if (OG_json_body.response.data['UID'] !== null) {    
            console.log("UID exists as it is a previously created virtual human")    
            console.log("The old UID is :", OG_json_body.response.data['UID'])    
            const currentDate = new Date();      
            const currentTimeInSeconds = Math.floor(currentDate.getTime() / 1000);     
                
            let oldUid = OG_json_body.response.data['UID'];  
          
            if (oldUid.length > 0 && changesMade === false) {  
                // let baseUid = oldUid.substring(0, oldUid.lastIndexOf('_'));    
                // tempUid = `${baseUid}_${currentTimeInSeconds}`;
                tempUid = oldUid;
            } else {  
                tempUid = `${previousVirtualHumanData.virtualhuman_name}_${currentTimeInSeconds}`;  
            }  
          
            console.log("Created a new Uid (if needed):", tempUid)    
        } 
        else
        {  
            console.log("OG_json_body.response.data['UID'] does not exist. Its value is : ", OG_json_body.response.data['UID'])
            const currentDate = new Date();  
            const currentTimeInSeconds = Math.floor(currentDate.getTime() / 1000); 
            tempUid = `${previousVirtualHumanData.virtualhuman_name}_${currentTimeInSeconds}`;
            console.log("Created a new Uid:", tempUid)
        }


        if (buttonClicked === "NextButton") {
            console.log("NextButton clicked")
            confirmKnowledgeSettings({
                'UID': tempUid,
                'role1': e.target.role1.value,
                'role2': e.target.role2.value,
                'Convo Background': e.target.convo_background.value,
                'Convo Mood': e.target.convo_mood.value,
                'Name': e.target.personal_name.value,
                'NRIC': e.target.personal_ic.value,
                'Date of Birth': e.target.personal_dob.value,
                'Age': e.target.demographic_age.value,
                'Gender': e.target.demographic_gender.value,
                'Occupation': e.target.demographic_occupation.value,
                'Ethnicity': e.target.demographic_ethnicity.value,
                'Nationality': e.target.demographic_nationality.value,
                'Religion': e.target.demographic_religion.value,
                'Income': e.target.socioeconomic_income.value,
                'Height': e.target.biometric_height.value,
                'Weight': e.target.biometric_weight.value,
                'Case Note': e.target.case_note.value,
            })
        } 
        else if (buttonClicked === "DraftButton") {  
            console.log("DraftButton clicked. Saving draft...")

            //const conversation_id = e.target.UID.value;
            const conversation_id = tempUid;
            // update the newVirtualHuman file
            let newData = {
                'UID': tempUid,
                'role1': e.target.role1.value,
                'role2': e.target.role2.value,
                'Convo Background': e.target.convo_background.value,
                'Convo Mood': e.target.convo_mood.value,
                'Name': e.target.personal_name.value,
                'NRIC': e.target.personal_ic.value,
                'Date of Birth': e.target.personal_dob.value,
                'Age': e.target.demographic_age.value,
                'Gender': e.target.demographic_gender.value,
                'Occupation': e.target.demographic_occupation.value,
                'Ethnicity': e.target.demographic_ethnicity.value,
                'Nationality': e.target.demographic_nationality.value,
                'Religion': e.target.demographic_religion.value,
                'Income': e.target.socioeconomic_income.value,
                'Height': e.target.biometric_height.value,
                'Weight': e.target.biometric_weight.value,
                'Case Note': e.target.case_note.value,
            };

            let newVirtualHuman = {...previousVirtualHumanData};
            //console.log(newVirtualHuman) // create a copy of the original VirtualHuman object

            let OG_JSON = JSON.parse(newVirtualHuman.vhc_json_body);
            //console.log(OG_JSON);
            OG_JSON.response.data = {...OG_JSON.response.data, ...newData};  
            console.log(OG_JSON);
            newVirtualHuman.vhc_json_body = JSON.stringify(OG_JSON);
            newVirtualHuman.status = "Unpublished"
            console.log(newVirtualHuman.vhc_json_body)
            // console.log(newVirtualHuman.conversation_id)
            newVirtualHuman.conversation_id = conversation_id;
            console.log("conversation_id:",newVirtualHuman.conversation_id)
            PutVirtualHuman(newVirtualHuman._id, newVirtualHuman.virtualhuman_name, newVirtualHuman.virtualhuman_description, newVirtualHuman.organisation, newVirtualHuman.sub_organisation, newVirtualHuman.custom_url, newVirtualHuman.character_id, newVirtualHuman.voice_id, newVirtualHuman.background_url, newVirtualHuman.conversation_id, newVirtualHuman.status, newVirtualHuman.vhc_json_body)
            
            
            setDraftSaved(true);  
            setChangesMade(false);
            setTimeout(() => {  
            setDraftSaved(false);
            setPreviousVirtualHumanData(newVirtualHuman)
            }, 1000);  // Change the button to "Saved" for 3 seconds
        }  
    }



    const buttonClass = `font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-blue-900 ${  
        changesMade ? "bg-orange-500" : "bg-gray-700"  
      } ${draftSaved ? "bg-green-500" : ""}`;  

    return (
        <form id='knowledge' className="flex-1 grid grid-cols-6 text-white" onSubmit={submitKnowledgeForm}>
            <SideBar />
            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">
                <div className="font-bold text-3xl flex items-center" style={{ marginBottom: "1rem" }}>  
                Create Your Virtual Human  
                        <div className="font-light text-3xl ml-4">  
                            - Conversation
                        </div>  
                </div> 
                <div className="h-96">

                    {formQuestions.map((section, index) => (
                        <div className="my-4" key={index}>
                            <div className='font-medium text-lg'>{section['title']}</div>
                            {section['questions'].map((question, index2) => <UserInputField key={index2} question={question} vhc_json_body={JSON.parse(previousVirtualHumanData.vhc_json_body)} autofill={autofill} setChangesMade={setChangesMade}/>)}
                        </div>
                    ))}
                </div>
            </div>

            <div className='flex justify-end'>
                <div>
                    <img className='bg-gray-900/80 rounded-lg w-48' src={selectedCharacter && selectedCharacter['src']} alt="" />

                    <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600' onClick={() => {setKnowledgeSettings(null); setVoiceSettings(null)}}>Back</button>
                    <input type='submit'  value='Next' onClick={() => setButtonClicked("NextButton")} className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600' />

                    <input type='submit' value='Save as Draft' onClick={() => setButtonClicked("DraftButton")} className={buttonClass} />  
                    
                </div>

            </div>
        </form>
    )
}

export default KnowledgeFormPage