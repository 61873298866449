import React, { useState, useEffect } from 'react';  
import { useNavigate, useLocation } from 'react-router-dom';  
import IntentAnalyticsDisplay from './IntentAnalyticsDisplay';  
import MainContainer from '../../components/MainContainer';
import SideBar from '../../components/SideBar';
import { ConvoAILogByConvoAIDocumentId } from '../../api/Analytics';
  
const ConvoAnalyticsPage = () => {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const [messages, setMessages] = useState([]);  
  const [showSummaryAnalytics, setShowSummaryAnalytics] = useState(false);  
  
  const intentAnalytics = location.state?.convoaiDocument?.Intent_Analytics;  
  const summaryAnalytics = location.state?.convoaiDocument?.Summary_Analytics;  
  const verboseIntentAnalytics = location.state?.convoaiDocument?.Verbose_Intent_Analytics;

  // const convoaiDocumentId = location.state?.convoaiDocument?.Summary_Analytics;  

  const userRole = location.state?.userDetails?.role;
  console.log("userRole: ", userRole)
  const [convoAILog, setConvoAILog] = useState(null);


  // useEffect(() => {  
  //   const searchParams = new URLSearchParams(location.search);  
  //   setShowSummaryAnalytics(searchParams.get('experimental') === 'true');  
  // }, [location.search]);  
  
  useEffect(() => {  
    const convo_history = location.state?.convoaiDocument.convo_history;  
    if (convo_history) {  
      setMessages(generateMessages(convo_history));  
    }  
  }, [location.state]);  
  
  function generateMessages(convo_history) {  
    const messages = [];  
  
    for (let i = 0; i < convo_history.length; i++) {  
      const message = convo_history[i];  
      console.log(message);  
      messages.push({  
        position: message.role === 'bot' ? 'left' : 'right',  
        text: message.text,  
        date: message.timestamp,  
      });  
    }  
  
    return messages;  
  }  

  // Toggle showSummaryAnalytics state  
  const handleToggleSummaryAnalytics = () => {  
    setShowSummaryAnalytics(!showSummaryAnalytics);  
  };  

  useEffect(() => {  
    const fetchConvoAILog = async () => {  
        try {  
            const log = await ConvoAILogByConvoAIDocumentId(location.state?.convoaiDocument._id);  
            setConvoAILog(log);  
        } catch (error) {  
            console.error('Error fetching Convo AI log:', error);  
        }  
    };  

    if (userRole === 'developer') {  
        fetchConvoAILog();  
    }  
}, [userRole])

  return (  
    <MainContainer>  
      <div className="w-full p-4">  
        <div className="big-window p-4" style={{ height: '90vh', overflowY: 'auto' }}> 
         <div className="overflow-y-auto mt-2 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 rounded-md" style={{ maxHeight: 'calc(100% - 3rem)' }}>  
             <style>  
               {`  
                 ::-webkit-scrollbar {  
                   background-color: #EAEAEA; 
                   width: 0.3rem; 
                   border-radius: 9999px;  
                 }  
  
                 ::-webkit-scrollbar-thumb {  
                   background-color: #3A3A3A;  
                   border-radius: 9999px;  
                 }  
               `}  
             </style> 
            <div className="flex flex-wrap">  
              {/* Intent Analytics & Summary Analytics */}  
              <div className="w-full lg:w-1/2 p-2">  
                <h2 className="font-bold text-2xl flex items-center text-white mb-6">  
                  Intent Analytics  
                </h2>  
                {intentAnalytics && (  
                  <IntentAnalyticsDisplay parentGroups={intentAnalytics['Parent Groups']} />  
                )}   

              {verboseIntentAnalytics && (  
                  <div className="window mt-6">  
                    <h3 className="font-bold text-xl text-white mb-4">  
                      Further Intent Analytics 
                    </h3>  
                    <div  
                      className="window-content bg-white p-4 rounded border border-gray-300 text-black"  
                      style={{ maxHeight: '500px', overflowY: 'auto' }}  
                    >  
                      <span style={{ whiteSpace: 'pre-wrap' }}>{verboseIntentAnalytics.suggestion}</span>  
                    </div>  
                  </div>  
                )}  

                {summaryAnalytics && (  
                  <div className="window mt-6">  
                    <h3 className="font-bold text-xl text-white mb-4">  
                      Summary Analytics  
                    </h3>  
                    {/* Display summaryAnalytics.suggestions in a window */}  
                    <div  
                      className="window-content bg-white p-4 rounded border border-gray-300 text-black"  
                      style={{ maxHeight: '500px', overflowY: 'auto' }}  
                    >  
                      <span style={{ whiteSpace: 'pre-wrap' }}>{summaryAnalytics.suggestion}</span>  
                    </div>  
                  </div>  
                )}  
              </div>  
              {/* Conversational History */}  
              <div className="w-full lg:w-1/2 p-2">  
                <h2 className="font-bold text-2xl flex items-center text-white mb-6">  
                  Conversational History  
                </h2>  
                <div  
                  className="overflow-y-auto p-4"  
                  style={{ maxHeight: '700px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}  
                >  
                  {messages.map((message, index) => (  
                    <div  
                      key={index}  
                      className={`my-2 ${  
                        message.position === 'left' ? 'text-left' : 'text-right'  
                      }`}  
                    >  
                      <div  
                        className={`inline-block px-4 py-2 rounded-lg ${  
                          message.position === 'left'  
                            ? 'bg-white text-black'  
                            : 'bg-blue-500 text-white'  
                        }`}  
                      >  
                        {message.text}  
                      </div>  
                      <div  
                        className={`text-xs mt-1 text-white ${  
                          message.position === 'left' ? 'text-left' : 'text-right'  
                        }`}  
                      >  
                        {new Date(message.date).toLocaleString()}  
                      </div>  
                    </div>  
                  ))}  
                </div>  


                {userRole === 'developer' && (
                  <div>
                    <h2 className="font-bold text-2xl flex items-center text-white mb-6">  
                      Conversational log (Developer Mode Only)
                    </h2> 
                    <pre className="text-white" 
                      style={{  
                        whiteSpace: 'pre-wrap',  
                        wordWrap: 'break-word',  
                        backgroundColor: 'rgba(20, 20, 20, 1)', // Add your desired background color here  
                        padding: '1rem', // Add padding for better readability  
                        borderRadius: '4px', // Add border-radius for a smooth appearance  
                      }}
                    > 
                        {JSON.stringify(convoAILog, null, 2)}  
                    </pre>
                  </div>
                )}

              </div>  
            </div>  
          </div>
        </div>  
      </div>
    </MainContainer>  
  );  
  
};  
  
export default ConvoAnalyticsPage;  
