const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "virtualhumans"
const clientId = `${process.env.REACT_APP_CF_ACCESS_CLIENT_ID}`
const clientSecret = `${process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET}`

export const getVirtualHumanById = async (id) => {
  
    const fetchUrl = `${url}/id/${id}`;
    console.log(fetchUrl)

    try {
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'CF-Access-Client-Id': clientId,
          'CF-Access-Client-Secret': clientSecret,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch VirtualHumans: ${response.status} ${response.statusText}`);
      }

       return response.json();

    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch VirtualHumans: unexpected error occurred');
    }

  };

export const getVirtualHumansByIds = async (ids) => {    
  if (ids.length === 0) {  
    return [];  
  }  
    
  const fetchUrl = `${url}/${ids.join(",")}`;    
  console.log(fetchUrl);    
    
  try {    
    const response = await fetch(fetchUrl, {    
      method: "GET",    
      headers: {    
        "Content-Type": "application/json",    
        "CF-Access-Client-Id": clientId,    
        "CF-Access-Client-Secret": clientSecret,    
      },  
    });  
    
    if (!response.ok) {    
      throw new Error(    
        `Failed to fetch VirtualHumans: ${response.status} ${response.statusText}`    
      );    
    }    
    
    return response.json();    
  } catch (error) {    
    console.error(error);    
    throw new Error("Failed to fetch VirtualHumans: unexpected error occurred");    
  }    
};  
    
  
// export const getVirtualHumansByIds = async (ids) => {  
//   const fetchUrl = `${url}/?ids=${ids.join(",")}`;  
//   console.log(fetchUrl);  
  
//   try {  
//     const response = await fetch(fetchUrl, {  
//       method: "GET",  
//       headers: {  
//         "Content-Type": "application/json",  
//         "CF-Access-Client-Id": clientId,  
//         "CF-Access-Client-Secret": clientSecret,  
//       },
//     });
  
//     if (!response.ok) {  
//       throw new Error(  
//         `Failed to fetch VirtualHumans: ${response.status} ${response.statusText}`  
//       );  
//     }  
  
//     return response.json();  
//   } catch (error) {  
//     console.error(error);  
//     throw new Error("Failed to fetch VirtualHumans: unexpected error occurred");  
//   }  
// };  

// const ids = ["605c72ef153237001f6470c8", "605c72ef153237001f6470c9", "605c72ef153237001f6470ca"];  
// getVirtualHumansByIds(ids)  
//   .then((virtualHumans) => console.log(virtualHumans))  
//   .catch((error) => console.error(error));  

export const getVirtualHumansByOrg = async (org) => {

  const fetchUrl = `${url}/org/${org}`;
  console.log(fetchUrl)

  try {
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch VirtualHumans: ${response.status} ${response.statusText}`);
    }

      return response.json();

  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch VirtualHuman: unexpected error occurred');
  }

};

export const getVirtualHumansBySubOrg = async (org, sub_org) => {

  const fetchUrl = `${url}/org/${org}/${sub_org}`;
  console.log(fetchUrl)

  try {
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'CF-Access-Client-Id': clientId,
        'CF-Access-Client-Secret': clientSecret,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch VirtualHumans: ${response.status} ${response.statusText}`);
    }

      return response.json();

  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch VirtualHumans: unexpected error occurred');
  }

};

  
export const PostVirtualHuman = async (virtualhuman_name,virtualhuman_description,organisation,sub_organisation,custom_url,character_id,voice_id,background_url,conversation_id,status,vhc_json_body) => {
  const formData = new FormData();
  formData.append('virtualhuman_name', virtualhuman_name);
  formData.append('virtualhuman_description', virtualhuman_description);
  formData.append('organisation', organisation);
  formData.append('sub_organisation', sub_organisation);
  formData.append('custom_url', custom_url);
  formData.append('character_id', character_id);
  formData.append('voice_id', voice_id);
  formData.append('background_url', background_url);
  formData.append('conversation_id', conversation_id);
  const currentDate = new Date();  
  const options = {  
    year: 'numeric',  
    month: 'numeric',  
    day: 'numeric',  
    hour: 'numeric',  
    minute: 'numeric',  
    second: 'numeric',  
    hour12: true  
  };  
  const formattedDate = currentDate.toLocaleString('en-US', options);  
  formData.append('date_added', formattedDate);
  formData.append('last_edited', formattedDate);
  formData.append('status', status);
  formData.append('vhc_json_body', vhc_json_body);
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'CF-Access-Client-Id': clientId,
                'CF-Access-Client-Secret': clientSecret,
            },
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const PutVirtualHuman = async (id,virtualhuman_name,virtualhuman_description,organisation,sub_organisation,custom_url,character_id,voice_id,background_url,conversation_id,status,vhc_json_body) => {
  const formData = new FormData();
  formData.append('virtualhuman_name', virtualhuman_name);
  formData.append('virtualhuman_description', virtualhuman_description);
  formData.append('organisation', organisation);
  formData.append('sub_organisation', sub_organisation);
  formData.append('custom_url', custom_url);
  formData.append('character_id', character_id);
  formData.append('voice_id', voice_id);
  formData.append('background_url', background_url);
  formData.append('conversation_id', conversation_id);
  const currentDate = new Date();  
  const options = {  
    year: 'numeric',  
    month: 'numeric',  
    day: 'numeric',  
    hour: 'numeric',  
    minute: 'numeric',  
    second: 'numeric',  
    hour12: true  
  };  
  const formattedDate = currentDate.toLocaleString('en-US', options);  
  formData.append('last_edited', formattedDate);
  formData.append('status', status);
  formData.append('vhc_json_body', vhc_json_body);

  const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "virtualhumans" + "/" + id;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                'CF-Access-Client-Id': clientId,
                'CF-Access-Client-Secret': clientSecret,
            },
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const updateIntentAnalytics = async (id, Intents) => {  
  const fetchUrl = `${url}/${id}/intentanalytics`; 
   
  console.log("intents",JSON.stringify(Intents));
  try {  
    const response = await fetch(fetchUrl, {  
      method: 'PUT',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      },  
      body: JSON.stringify(Intents),  
    });  
  
    if (!response.ok) {  
      throw new Error(`Failed to update intent analytics: ${response.status} ${response.statusText}`);  
    }  
  
    return response.json();  
  
  } catch (error) {  
    console.error(error);  
    throw new Error('Failed to update intent analytics: unexpected error occurred');  
  }  
};  


export const updateSummaryAdvice = async (id, summaryadvice) => {  
  const fetchUrl = `${url}/${id}/summaryadvice`; 
   
  console.log("upload",JSON.stringify(summaryadvice));
  try {  
    const response = await fetch(fetchUrl, {  
      method: 'PUT',  
      headers: {  
        'Content-Type': 'application/json',  
        'CF-Access-Client-Id': clientId,  
        'CF-Access-Client-Secret': clientSecret,  
      },  
      body: JSON.stringify({  
        summary_advice: summaryadvice,  
      }),  
    });  
  
    if (!response.ok) {  
      throw new Error(`Failed to update intent analytics: ${response.status} ${response.statusText}`);  
    }  
  
    return response.json();  
  
  } catch (error) {  
    console.error(error);  
    throw new Error('Failed to update intent analytics: unexpected error occurred');  
  }  
};  


export const deleteVirtualHuman = async (id) => {
    console.log(id)
    const url = `${process.env.REACT_APP_VHC_BACKEND_HTTPS_URL}` + "virtualhumans" + "/" + id;
    console.log(url)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'CF-Access-Client-Id': clientId,
                'CF-Access-Client-Secret': clientSecret,
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to delete VirtualHuman with ID ${id}: ${response.statusText}`);
        }
        return response;
    } catch (error) {
        throw new Error(`Failed to delete VirtualHuman with ID ${id}: ${error.message}`);
    }
};