import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar"
import { updateProjectNameAndDescription } from "../../api/Projects";
// import UserInputField from "../../components/UserInputField"
// import { formQuestions } from "../../data/CreationFormData"
// import { PutProject } from "../../api/Projects";

const ProjectInformationPage = ({
    userDetails,
    previousProjectData,
    setPreviousProjectData,
    setProjectInfo,

    projectSceneName,
    setProjectSceneName,

    projectSceneDescription,
    setProjectSceneDescription,

}) => {
    const id = userDetails._id;
    const navigate = useNavigate();

    console.log("ProjectInformationPage")

    const [changesMade, setChangesMade] = useState(false);
    const [ProjectName, setProjectName] = useState(previousProjectData.project_name)
    const [ProjectDescription, setProjectDescription] = useState(previousProjectData.project_description)

    useEffect(() => {
        if (projectSceneName !== null){
            setProjectName(projectSceneName)
        }
        else if (previousProjectData.project_name !== null)
        {
            setProjectName(previousProjectData.project_name)
        }

        if (projectSceneDescription !== null){
            setProjectDescription(projectSceneDescription)
        }
        else if (previousProjectData.project_description !== null)
        {
            setProjectDescription(previousProjectData.project_description)
        }
    }, [])

    const saveAsDraft = () => {
        if (changesMade === true)
        {

            const projectId = previousProjectData._id;  
            const newName = ProjectName;  
            const newDescription = ProjectDescription;  
            const editedBy = id;  

              
            updateProjectNameAndDescription(projectId, newName, newDescription, editedBy)  
              .then((result) => console.log('Project name and description updated successfully:', result))  
              .catch((error) => console.error('Error updating project name and description:', error));  
        }
        else
        {
            console.log("No changes made")
        }
    }

    const handleProjectDescriptionChange = (e) => {
        // console.log(e.target.value)
        setProjectDescription(e.target.value)
        setProjectSceneDescription(e.target.value)
        setChangesMade(true)
    }
    const handleProjectNameChange = (e) => {
        // console.log(e.target.value)
        setProjectName(e.target.value)
        setProjectSceneName(e.target.value)
        setChangesMade(true)
    }

    // const buttonClass = `bg-gray-700 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-blue-900 ${changesMade ? 'bg-orange-500' : ''} ${draftSaved ? 'bg-green-500' : ''}`;  
    
    return (  
        <div id="Project Information" className="flex-1 grid grid-cols-6 text-white text-left">  
            <SideBar />  
            <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">  
            <div className="font-bold text-3xl flex items-center">  
            Create Your Project
                <div className="font-light text-3xl ml-4">  
                    - Name And Description  
                </div>  
            </div>   
                <form>  
                    <div className="my-4 flex flex-row items-center">  
                        <label htmlFor="ProjectName" className="block font-medium mb-2 w-32 mr-4 ">Scenario Name</label>  
                        <input type="text" id="ProjectName" className="border rounded-lg py-2 px-3 w-full" style={{ color: 'black' }} defaultValue={ProjectName} onChange={handleProjectNameChange}/>  
                    </div>  
                    <div className="my-4 flex flex-row items-center">  
                            <label htmlFor="ProjectDescription" className="block font-medium mb-2 w-32 mr-4">Scenario Description</label>  
                            <textarea id="ProjectDescription" rows="4" className="border rounded-lg py-2 px-3 w-full" style={{ color: 'black' }} defaultValue={ProjectDescription} onChange={handleProjectDescriptionChange}></textarea>  
                    </div>  
                    <div className="my-4 flex flex-row justify-between">  
                        <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-medivrlb-600' onClick={() => {navigate("/Projects"); saveAsDraft();}}>Back</button>  
                        <div className="flex justify-end">  
                            <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-40 my-4 hover:bg-medivrlb-600' onClick={() => {setProjectInfo(true); saveAsDraft();}}>Next</button>  
                        </div>  
                    </div>  
                </form>  
            </div>  
        </div>  
    )  
}

export default ProjectInformationPage