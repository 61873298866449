import React, { useState, useEffect } from 'react';  
import SideBar from '../../components/SideBar';  
import MainContainer from '../../components/MainContainer';  
import minus_cirle from "../../img/minus-circle.png";  
  
import { useNavigate, useLocation } from "react-router-dom";  
  
import { updateIntentAnalytics, updateSummaryAdvice } from '../../api/VirtualHumans';  
  
const IntentAnalyticsPage = () => {  
  
  const location = useLocation();  
  const navigate = useNavigate();
  
  const [virtualHuman, setVirtualHuman] = useState(null); 
  const [projectData, setProjectData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const [parentGroups, setParentGroups] = useState([]);
  const [Saved, setSaved] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const [showSummaryAdviceInput, setShowSummaryAdviceInput] = useState(false);  
  const [summaryAdvice, setSummaryAdvice] = useState("");  


  useEffect(() => {  
    setVirtualHuman(location.state?.virtualHuman);  
    setProjectData(location.state?.projectData);
    setUserDetails(location.state?.userDetails);

    // let tempPG = intentAnalyticsInputToParentGroups(location.state.virtualHuman?.Intents); 
  }, []); // Pass an empty dependency array to run only when the component mounts 

  useEffect(() => {
    console.log("reached here")
    setParentGroups(intentAnalyticsInputToParentGroups(virtualHuman?.Intents))
    console.log("parentGroups",parentGroups)
    setSummaryAdvice(virtualHuman?.summary_advice || "");  
    if (virtualHuman?.summary_advice) {  
      setShowSummaryAdviceInput(true);  
    }
  }, [virtualHuman]);

  
  console.log("virtualHuman: ", virtualHuman);  
  console.log("previous intent analytics data input:", virtualHuman?.Intents);  
  console.log("projectData:" , projectData)
  console.log("userDetails:" , userDetails)
  
  const intentAnalyticsInputToParentGroups = (input) => {  
    console.log("input: ", JSON.stringify(input));  
    if (!input) {  
      console.log("error!");  
      return [];  
    }  
    
    return Object.keys(input).map((groupName) => ({  
      name: groupName,  
      intents: input[groupName],  
    }));  
  };  
  
  const handleAddParentGroup = () => {  
    const newParentGroup = {  
      name: '',  
      intents: [],  
    };  
    setParentGroups([...parentGroups, newParentGroup]);  
    setChangesMade(true);
    setSaved(false);
  };  
  
  const handleAddIntent = (parentGroupIndex) => {  
    const newIntent = '';  
    const updatedParentGroups = [...parentGroups];  
    updatedParentGroups[parentGroupIndex].intents.push(newIntent);  
    setParentGroups(updatedParentGroups);  
    setChangesMade(true);
    setSaved(false);
  };  
  
  const generateJson = () => {  
    const jsonIntents = {};  
  
    parentGroups.forEach((parentGroup) => {  
      jsonIntents[parentGroup.name] = parentGroup.intents;  
    });  
  
    return jsonIntents;  
  };  
  
  const handleGenerateJson = () => {  
    const json = generateJson();  
    console.log(JSON.stringify(json));  
  };  
  
  const handleSaveIntents = async () => {  
    const json = generateJson();  
    try {  
      const result = await updateIntentAnalytics(virtualHuman._id, json);  
      console.log(result);  
    } catch (error) {  
      console.error(error);  
    }  
    setChangesMade(false);
    setSaved(true);
  };  
  
  const handleDeleteParentGroup = (parentGroupIndex) => {  
    const updatedParentGroups = [...parentGroups];  
    updatedParentGroups.splice(parentGroupIndex, 1);  
    setParentGroups(updatedParentGroups);  
  };  
  
  const handleDeleteIntent = (parentGroupIndex, intentIndex) => {  
    const updatedParentGroups = [...parentGroups];  
    updatedParentGroups[parentGroupIndex].intents.splice(intentIndex, 1);  
    setParentGroups(updatedParentGroups);  
  };  

  const returnToVirtualHumanPage = () => {
    navigate("/virtualhumans", {  
      state: { projectData: projectData, userDetails: userDetails },  
      }); 
  }

  const handleUploadSummaryAdvice = async () => {  
    try {  
      const result = await updateSummaryAdvice(virtualHuman._id, summaryAdvice);  
      console.log(result);  
    } catch (error) {  
      console.error(error);  
    }  
  };  


  
  const buttonClass = `bg-gray-700 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-blue-900 ${changesMade ? 'bg-orange-500' : ''} ${Saved ? 'bg-green-500' : ''}`;  

  return (  
    <MainContainer>  
      <div id="Intent Analytics Page" className="flex-1 grid grid-cols-6 text-white">  
        <SideBar />  
        <div className="col-span-4 pl-4 overflow-y-auto scrollbar-hide">  
          <div className="font-bold text-3xl flex items-center" style={{ marginBottom: '1rem' }}>  
            Create Your Virtual Human  
            <div className="font-light text-3xl ml-4">- Intent Analytics</div>  
          </div>  
  
          <div className="rounded-full" style={{ display: 'flex', flexDirection: 'column', height: '300px', width: '90%' }}>  
            {parentGroups.map((parentGroup, parentGroupIndex) => (  
              <div key={parentGroupIndex} className="bg-medivrlb-800 p-5 mb-5 rounded-md relative">  
                <button  
                  onClick={() => handleDeleteParentGroup(parentGroupIndex)} >  
                  <img src={minus_cirle} alt="Delete" style={{ width: "30px", height: "30px" }} className="absolute top-0 right-0 text-white font-bold py-1 px-1 rounded" />  
                </button>  
                <div style={{ marginBottom: '2rem' }} className="flex">  
                  <label className="mr-5">Parent Group Name:</label>  
  
                  <textarea  
                    className="rounded-lg w-100 p-2 bg-white text-black"  
                    value={parentGroup.name}  
                    onChange={(e) => {  
                      const updatedParentGroups = [...parentGroups];  
                      updatedParentGroups[parentGroupIndex].name = e.target.value;  
                      setParentGroups(updatedParentGroups);  
                    }}  
                    style={{ color: 'black' }}  
                  />  
                </div>  
  
                <div className="intents bg-gray-700 p-4 rounded-md">  
                  {parentGroup.intents.map((intent, intentIndex) => (  
                    <div key={intentIndex} className="mb-2 flex">  
                      <input  
                        value={intent}  
                        onChange={(e) => {  
                          const updatedParentGroups = [...parentGroups];  
                          updatedParentGroups[parentGroupIndex].intents[intentIndex] =  
                            e.target.value;  
                          setParentGroups(updatedParentGroups);  
                        }}  
                        style={{ color: 'black', width: '100%' }}  
                      />  
                      <button  
                        onClick={() => handleDeleteIntent(parentGroupIndex, intentIndex)} >  
                        <img src={minus_cirle} alt="Delete" style={{ width: '90%', height: '90%' }} />  
                      </button>  
                    </div>  
                  ))}  
                  <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 my-4 hover:bg-medivrlb-600' style={{ maxHeight: "30px" }} onClick={() => handleAddIntent(parentGroupIndex)}>Add Intent</button>  
                </div>  
              </div>  
            ))}  
            <button className='bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-60 my-4 hover:bg-medivrlb-600' onClick={handleAddParentGroup}>Add Parent Group</button>  
            <button  
              className='bg-orange-500 font-medium rounded-full px-2 py-1 w-60 my-4 hover:bg-orange-600'  
              onClick={() => {  
                if (summaryAdvice) {  
                  handleUploadSummaryAdvice();  
                } else {  
                  setShowSummaryAdviceInput(!showSummaryAdviceInput);  
                }  
              }}  
            >  
              {summaryAdvice ? "Upload Summary Advice" : "Experimental Feature"}  
            </button>  
 
          {showSummaryAdviceInput && (  
            <div className="mt-2">  
              <label className="mr-5">Summary Advice:</label>  
              <textarea  
                className="rounded-lg w-100 p-2 bg-white text-black"  
                value={summaryAdvice}  
                onChange={(e) => {  
                  setSummaryAdvice(e.target.value);  
                }}  
                placeholder="Enter summary advice here..."  
                style={{ color: 'black' }}  
              />  
            </div>  
          )}
          </div>  
        </div>  
        <div className="flex flex-col justify-start items-center mt-4">  
        <div className="w-60">  
          <button  
            className="bg-medivrlb-500 font-medium rounded-full px-2 py-1 w-full my-4 hover:bg-medivrlb-600"  
            onClick={returnToVirtualHumanPage}  
          >  
            Return to Virtual Humans  
          </button>  
        </div>  
        <div className="w-60">  
          <button  
            className={`${buttonClass} w-full`}  
            onClick={handleSaveIntents}  
          >  
            Save Intents  
          </button>  
        </div>  
      </div>  
      </div>  
    </MainContainer>  
  );  
};  
  
export default IntentAnalyticsPage;  
